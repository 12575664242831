import { render, staticRenderFns } from "./IPCell.vue?vue&type=template&id=04f4fb02&scoped=true&"
import script from "./IPCell.vue?vue&type=script&lang=js&"
export * from "./IPCell.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f4fb02",
  null
  
)

export default component.exports