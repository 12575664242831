<template>
  <div class="events-cell-container">
    <WButton success outline sm
      v-tooltip.top-center="{
        content: $t('clients.eventsTooltipCaption'),
        popperOptions: {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window'
            }
          }
        }
      }"
      @click.prevent.stop="handleEventsButtonClick"
    >
      {{ $t('clients.eventsButtonCaption') }}
    </WButton>
    <WButton success outline sm
      v-tooltip.top-center="{
        content: $t('clients.eventsAndRssiTooltipCaption'),
        popperOptions: {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window'
            }
          }
        }
      }"
      @click.prevent.stop="handleEventsAndRSSIButtonClick"
    >
      {{ $t('clients.eventsAndRssiButtonCaption') }}
    </WButton>
    <WButton v-if="isUserAdmin" success outline sm
      @click.prevent.stop="handleDisconnectAndDeauthorizeButtonClick"
    >
      {{ $t('clients.disconnectAndDeauthorizeButtonCaption') }}
    </WButton>
  </div>
</template>

<script>
/**
 * компонент - ячейка events.
 * компонент локальный: используется только в ClientsTable.vue
 * @component
 */

export default {
  name: 'EventsCell',
  components: {},
  computed: {
    isUserAdmin() {
      return this.$store.state.userData.role === 'admin';
    }
  },
  props: {
  },
  methods: {
    handleEventsButtonClick(event) {
      this.$emit('on-events-button-click', event);
    },
    handleEventsAndRSSIButtonClick(event) {
      this.$emit('on-events-and-rssi-button-click', event);
    },
    handleDisconnectAndDeauthorizeButtonClick(event) {
      this.$emit('on-disconnect-and-deauthorize-button-click', event);
    }
  }
};
</script>

<style lang="css" scoped>
.events-cell-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: min-content;
}
</style>
