<template>
  <span
    v-if="!isTypeWired"
    class="badge badge-light wlan-cell-badge"
    :class="[wlanName ? 'badge-primary' : 'badge-default']"
    @click.stop="handleWlanNameClick"
  >
    <i class="fa fa-wifi" />
    <span v-if="wlanName">
      {{ wlanName }}
    </span>
    <span v-else
      class="tooltip-text"
      v-tooltip.top-center="{ content: 'WLAN ID: ' + props.row.wlan }"
    >
      {{ $t('clients.tableNotFound') }}
    </span>
  </span>

  <span v-else class="muted">-</span>
</template>

<script>
/**
 * компонент - ячейка wlan.
 * компонент локальный: используется только в ClientsTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'WlanCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    isTypeWired() {
      return this.props.row.nativeData.Type === 'wired';
    },
    wlanName() {
      const wlanNamebyId = commonService.wlanNamebyId(this.props.row.wlan);
      return wlanNamebyId;
    }
  },
  methods: {
    handleWlanNameClick() {
      this.$emit('on-wlan-name-click');
    }
  }
};
</script>

<style lang="css" scoped>
.wlan-cell-badge {
  transition: var(--transition);
}
.wlan-cell-badge:hover {
  opacity: .6;
}
.muted {
  color: var(--gray-light);
}
</style>
