var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events-cell-container"},[_c('WButton',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
      content: _vm.$t('clients.eventsTooltipCaption'),
      popperOptions: {
        modifiers: {
          preventOverflow: {
            boundariesElement: 'window'
          }
        }
      }
    }),expression:"{\n      content: $t('clients.eventsTooltipCaption'),\n      popperOptions: {\n        modifiers: {\n          preventOverflow: {\n            boundariesElement: 'window'\n          }\n        }\n      }\n    }",modifiers:{"top-center":true}}],attrs:{"success":"","outline":"","sm":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleEventsButtonClick($event)}}},[_vm._v(" "+_vm._s(_vm.$t('clients.eventsButtonCaption'))+" ")]),_c('WButton',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
      content: _vm.$t('clients.eventsAndRssiTooltipCaption'),
      popperOptions: {
        modifiers: {
          preventOverflow: {
            boundariesElement: 'window'
          }
        }
      }
    }),expression:"{\n      content: $t('clients.eventsAndRssiTooltipCaption'),\n      popperOptions: {\n        modifiers: {\n          preventOverflow: {\n            boundariesElement: 'window'\n          }\n        }\n      }\n    }",modifiers:{"top-center":true}}],attrs:{"success":"","outline":"","sm":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleEventsAndRSSIButtonClick($event)}}},[_vm._v(" "+_vm._s(_vm.$t('clients.eventsAndRssiButtonCaption'))+" ")]),(_vm.isUserAdmin)?_c('WButton',{attrs:{"success":"","outline":"","sm":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleDisconnectAndDeauthorizeButtonClick($event)}}},[_vm._v(" "+_vm._s(_vm.$t('clients.disconnectAndDeauthorizeButtonCaption'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }