var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"with-badge-status active",class:[
    _vm.props.row.nativeData.State === 'CONNECTED' && 'active',
    _vm.props.row.nativeData.State === 'DISCONNECTED' && 'inactive'
  ]},[_c('i',{staticClass:"fa",class:{
      'fa-plug': _vm.props.row.nativeData.Type === 'wired',
      'fa-video-camera': _vm.props.row.nativeData.Type === 'camera',
      'fa-mobile': _vm.props.row.nativeData.Type === 'other'
    }}),(
        _vm.props.row.nativeData.Type === 'camera' &&
        _vm.props.row.nativeData.data &&
        _vm.props.row.nativeData.data.rtsp_stream &&
        _vm.props.row.nativeData.State === 'CONNECTED'
      )?_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.handleClickVideoMacCell($event)}}},[_vm._v(" "+_vm._s(_vm.macName)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.macName)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }