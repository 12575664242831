<template>
  <WTable
    style="height: calc(70vh)"
    max-height="calc(70vh - 100px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :select-options="{
      enabled: isSelectionRowsModeEnabled,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
    :pagination-options="{
      enabled: false, // info: отключена из-за неовместимости с легаси Clients.vue
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:emptystate>
      <slot name="emptystate"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'mac'">
        <MacCell
          :props="props"
          @on-click-video-mac-cell="handleClickVideoMacCell(props.row.nativeData.data.rtsp_stream)"
        />
      </template>

      <template v-else-if="props.column.field === 'ip'">
        <IPCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'cpe'">
        <CPECell
          :props="props"
          @on-cpe-name-click="handleCpeNameClick(props.row.cpe)"
        />
      </template>

      <template v-else-if="props.column.field === 'wlan'">
        <WlanCell
          :props="props"
          @on-wlan-name-click="handleWlanNameClick(props.row.wlan)"
        />
      </template>

      <template v-else-if="props.column.field === 'events'">
        <EventsCell
          @on-events-button-click="handleEventsButtonClick(props.row.nativeData)"
          @on-events-and-rssi-button-click="handleEventsAndRSSIButtonClick(props.row.nativeData)"
          @on-disconnect-and-deauthorize-button-click="handleDisconnectAndDeauthorizeButtonClick(props.row.nativeData)"
        />
      </template>

      <template v-else>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom>
      <slot name="table-actions-bottom"></slot>
    </template>

  </WTable>
</template>

<script>
/**
 * компонент - таблица статистики по clients.
 * компонент локальный: используется только в Clients.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

import {
  MacCell,
  IPCell,
  CPECell,
  WlanCell,
  EventsCell
} from './components';

export default {
  name: 'ClientsTable',
  components: {
    MacCell,
    IPCell,
    CPECell,
    WlanCell,
    EventsCell
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    activeRowClientId: {
      type: String,
      default: ''
    },
    selectedRowClientsIds: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    },
    isSelectionRowsModeEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        mac: false,
        ip: false,
        cpe: false,
        wlan: false,
        events: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            mac: this.isTableColumnHidden.mac,
            ip: this.isTableColumnHidden.ip || this.isOneColMode,
            cpe: this.isTableColumnHidden.cpe,
            wlan: this.isTableColumnHidden.wlan,
            events: this.isTableColumnHidden.events || this.isOneColMode
          },
          additionalRules: {}
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        clientsData: this.items,
        selectedRowClientsIds: this.selectedRowClientsIds
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowClientId = row.nativeData.id;

      return this.activeRowClientId === rowClientId ? 'clients-table__active-row' : '';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    },
    handleClickVideoMacCell(rtspStream) {
      this.$emit('on-open-video-modal', rtspStream);
    },
    handleCpeNameClick(cpeId) {
      this.$emit('on-cpe-name-click', cpeId);
    },
    handleWlanNameClick(wlanId) {
      this.$emit('on-wlan-name-click', wlanId);
    },
    handleEventsButtonClick(rowNativeData) {
      this.$emit('on-events-button-click', rowNativeData);
    },
    handleEventsAndRSSIButtonClick(rowNativeData) {
      this.$emit('on-events-and-rssi-button-click', rowNativeData);
    },
    handleDisconnectAndDeauthorizeButtonClick(rowNativeData) {
      this.$emit('on-disconnect-and-deauthorize-button-click', rowNativeData);
    }
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.clients-table-td-wrapper {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clients-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.clients-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}
</style>
