<template>
  <div class="row">
    <div class="col-lg-12" v-if="showAddButton">
      <div class="row filter-block w-100 ml-0 mr-0 pl-0 pr-0 justify-content-start">
        <div class="pl-0 col-lg-3 d-flex align-items-start">
          <div class="w-100">
            <div class="filters__search mb-0 height-custom-for-filters-in-clients">
              <div class="input-group input-group-sm filters__input filters__input--clients">
                <span class="input-group-addon">
                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>
                  <i class="fa fa-search" v-else></i>
                </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('general.search')"
                  class="form-control"
                  v-model="searchValue"
                />
                <span class="clear-input text-muted">
                  <i
                    class="fa fa-remove"
                    v-if="searchValue && !searchSpinner"
                    @click="() => {
                      resetFilterQuery();
                      searchInputHandler('')
                    }"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!--                <div class="pl-0 col-lg-3 pr-0">-->
        <!--          <div class="input-group input-group-sm d-flex align-items-end min-width-for-filters-in-clients">-->
        <!--            <div class="input-group input-group-sm flex-wrap">-->
        <!--&lt;!&ndash;              <label for="radio" class="filters__label lables-in-clients-filters">&ndash;&gt;-->
        <!--&lt;!&ndash;                <small>{{ $t('general.location') }}</small>&ndash;&gt;-->
        <!--&lt;!&ndash;              </label>&ndash;&gt;-->
        <!--              <div class="d-flex w-100">-->
        <!--                <div>-->
        <!--                  <span class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">-->
        <!--                    <i class="fa fa-map-marker"></i>-->
        <!--                  </span>-->
        <!--                </div>-->
        <!--                <div class="w-100">-->
        <!--                  <select-->
        <!--                    :disabled="isLoadingCLients"-->
        <!--                    @change="locationFilterSelectHandle"-->
        <!--                    class="form-control form-control-sm filters__field height-custom-for-filters-in-clients height-custom-for-filters-in-clients-custom-padding location-filters-in-clients"-->
        <!--                    v-model="filters.location"-->
        <!--                  >-->
        <!--                    <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
        <!--                    <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
        <!--                      {{ location.name }}-->
        <!--                    </option>-->
        <!--                  </select>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;                <div&ndash;&gt;-->
        <!--            &lt;!&ndash;                  v-if="this.filters.location !== 'All locations'"&ndash;&gt;-->
        <!--            &lt;!&ndash;                  class="d-flex justify-content-start align-items-center  with-child-wrapper ">&ndash;&gt;-->
        <!--            &lt;!&ndash;                  <div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <input&ndash;&gt;-->
        <!--            &lt;!&ndash;                      type="checkbox"&ndash;&gt;-->
        <!--            &lt;!&ndash;                      v-model="filters.with_childs"&ndash;&gt;-->
        <!--            &lt;!&ndash;                    />&ndash;&gt;-->
        <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                  <div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                    <small class="with-child">{{$t('general.withChild')}}</small>&ndash;&gt;-->
        <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->

        <!--            &lt;!&ndash;                </div>&ndash;&gt;-->

        <!--            <div-->
        <!--              class="input-group input-group-sm d-flex align-items-end ml-0 align-self-end justify-content-start height-custom-for-filters-in-clients location-filter-with-childs"-->
        <!--            >-->
        <!--              <div class="d-flex switcher-with-childs-in-clients-wrapper align-items-end">-->
        <!--                <div class="d-flex align-items-end justify-content-start">-->
        <!--                  <Switch-component-->
        <!--                    @change="withChildsFilterSwitchHandle"-->
        <!--                    :disabled="this.filters.location === 'All locations' || isLoadingCLients"-->
        <!--                    v-model="filters.with_childs"-->
        <!--                    class="switcher-with-childs-in-clients"-->
        <!--                  />-->
        <!--                </div>-->
        <!--                <div class="input-group input-group-sm filters__location switcher-with-childs-in-clients-caption">-->
        <!--                  <small class="with-child">{{ $t('general.withChild') }}</small>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

                                        <div class="pl-0 col-lg-3" v-if="areLocationsVisible">
                                                                                                            <div
                  class="input-group-for-location-select-in-clients--filters   ">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-clients--filters"
                    enable-max-option-width
                    small-size
                   :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="withChildsFilterSwitchHandle"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                </div>
                </div>

        <div class="pl-0 col-lg-3">
          <div class="input-group input-group-sm flex-wrap min-width-for-filters-in-clients multiselector-in-clients">
            <!--            <label for="wlan-filter" class="filters__label lables-in-clients-filters">-->
            <!--              <small>{{ $t('statistics.filtersCPE') }}</small>-->
            <!--            </label>-->
            <!--                <span class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">-->
            <!--                  <i class="fa fa-wifi"></i>-->
            <!--                </span>-->
            <div class="d-flex w-100">
              <div>
                <span class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">
                  <!--                  <i class="fa fa-wifi"></i>-->
                  <img
                    class="img img-responsive cpe-icon"
                    src="/static/img/router-icon.svg"
                    alt
                  />
                </span>
              </div>
              <MultiselectWrapperForUseInFilters z-index="999998">
                <Multiselect
                  id="cpe"
                  class=" "
                  v-model="selectedCpeInMultiselector"
                  :multiple="true"
                  :max="1"
                  @input="selectCpeInCpeFiltersHandler"
                  :options="cpesCompactListForFilter || []"
                  trackBy="id"
                  label="name"
                  :disabled="isLoadingCLients"
                  :placeholder="$t('statistics.allCpes')"
                  :selectLabel="$t('general.select')"
                  deselectLabel="x"
                  selectedLabel=""
                >
                  <template v-slot:noOptions>
                    {{ $t('general.notFound') }}
                  </template>
                  <template v-slot:noResult>
                    {{ $t('general.notFound') }}
                  </template>
                  <template v-slot:maxElements>
                    {{ $t('general.maxOneElement') }}
                  </template>
                </Multiselect>
              </MultiselectWrapperForUseInFilters>

            </div>
          </div>
        </div>
        <div class="pl-0 col-lg-3">
          <div class="input-group input-group-sm flex-wrap min-width-for-filters-in-clients multiselector-in-clients">
            <!--            <label for="wlan-filter" class="filters__label lables-in-clients-filters">-->
            <!--              <small>{{ $t('statistics.filtersWLAN') }}</small>-->
            <!--            </label>-->
            <div class="d-flex w-100">
              <div>
                <span class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">
                  <i class="fa fa-wifi"></i>
                </span>
              </div>
              <MultiselectWrapperForUseInFilters z-index="999998">
                <Multiselect
                id="wlan-filter"
                class=""
                v-model="selectedWlanInMultiselector"
                :multiple="true"
                :max="1"
                @input="selectWlanInWlanFiltersHandler"
                :options="wlansCompactListForFilter || []"
                trackBy="id"
                label="ssid"
                :disabled="isLoadingCLients"
                :placeholder="$t('aps.allWlans')"
                :selectLabel="$t('general.select')"
                deselectLabel="x"
                selectedLabel=""
              >
                  <template v-slot:noOptions>
                    {{ $t('general.notFound') }}
                  </template>
                  <template v-slot:noResult>
                    {{ $t('general.notFound') }}
                  </template>
                  <template v-slot:maxElements>
                    {{ $t('general.maxOneElement') }}
                  </template>
                </Multiselect>
              </MultiselectWrapperForUseInFilters>
            </div>
          </div>
        </div>

      </div>
      <!--            <div class="row filter-block w-100">-->
      <!--        <div class="w-100 d-flex justify-content-start flex-wrap flex-gap-1">-->

      <!--          <div class="mb-1">-->
      <!--                          <div class="input-group input-group-sm  flex-wrap mr-1 min-width-for-filters-in-clients multiselector-in-clients">-->
      <!--                <label for="wlan-filter" class="filters__label lables-in-clients-filters">-->
      <!--                  <small>{{ $t('statistics.filtersCPE') }}</small>-->
      <!--                </label>-->
      <!--&lt;!&ndash;                <span class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">&ndash;&gt;-->
      <!--&lt;!&ndash;                  <i class="fa fa-wifi"></i>&ndash;&gt;-->
      <!--&lt;!&ndash;                </span>&ndash;&gt;-->
      <!--                            <div class="d-flex w-100">-->
      <!--                  <div>-->
      <!--                    <span-->
      <!--                      class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">-->
      <!--                  <i class="fa fa-wifi"></i>-->
      <!--                </span>-->
      <!--                  </div>-->
      <!--                  <Multiselect-->
      <!--                    id="cpe"-->
      <!--                    class="height-custom-for-filters-in-clients multiselector-in-clients"-->
      <!--                    v-model="selectedCpeInMultiselector"-->
      <!--                    :multiple="false"-->
      <!--                    @input="selectCpeInCpeFiltersHandler"-->
      <!--                    :options="cpesCompactListForFilter || []"-->
      <!--                    trackBy="id"-->
      <!--                    label="name"-->
      <!--                    :disabled="isLoadingCLients"-->
      <!--                    :placeholder="$t('statistics.allCpes')"-->
      <!--                    :selectLabel="$t('general.select')"-->
      <!--                    deselectLabel="x"-->
      <!--                    selectedLabel=""-->
      <!--                  >-->
      <!--                    <template v-slot:noOptions>-->
      <!--                      {{$t('general.notFound')}}-->
      <!--                    </template>-->
      <!--                    <template v-slot:noResult>-->
      <!--                      {{$t('general.notFound')}}-->
      <!--                    </template>-->
      <!--                  </Multiselect>-->
      <!--                </div>-->
      <!--                          </div>-->
      <!--          </div>-->
      <!--          <div class="mb-1">-->
      <!--                          <div class="input-group input-group-sm  flex-wrap mr-1 min-width-for-filters-in-clients multiselector-in-clients">-->
      <!--                <label for="wlan-filter" class="filters__label lables-in-clients-filters">-->
      <!--                  <small>{{ $t('statistics.filtersWLAN') }}</small>-->
      <!--                </label>-->
      <!--                <div class="d-flex w-100">-->
      <!--                  <div>-->
      <!--                  <span-->
      <!--                    class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">-->
      <!--                  <i class="fa fa-wifi"></i>-->
      <!--                </span>-->
      <!--                  </div>-->

      <!--                  <Multiselect-->
      <!--                    id="wlan-filter"-->
      <!--                    class="height-custom-for-filters-in-clients multiselector-in-clients"-->
      <!--                    v-model="selectedWlanInMultiselector"-->
      <!--                    :multiple="false"-->
      <!--                    @input="selectWlanInWlanFiltersHandler"-->
      <!--                    :options="wlansCompactListForFilter || []"-->
      <!--                    trackBy="id"-->
      <!--                    label="ssid"-->
      <!--                    :disabled="isLoadingCLients"-->
      <!--                    :placeholder="$t('aps.allWlans') "-->
      <!--                    :selectLabel="$t('general.select')"-->
      <!--                    deselectLabel="x"-->
      <!--                    selectedLabel=""-->
      <!--                  >-->
      <!--                    <template v-slot:noOptions>-->
      <!--                      {{$t('general.notFound')}}-->
      <!--                    </template>-->
      <!--                    <template v-slot:noResult>-->
      <!--                      {{$t('general.notFound')}}-->
      <!--                    </template>-->
      <!--                  </Multiselect>-->
      <!--                </div>-->

      <!--              </div>-->
      <!--          </div>-->
      <!--                              <div class="mb-1 ">-->

      <!--            <div-->
      <!--              class="input-group input-group-sm ml-1 d-flex align-items-end  min-width-for-filters-in-clients">-->
      <!--              <div class="input-group input-group-sm flex-wrap">-->

      <!--                <label for="radio" class="filters__label lables-in-clients-filters">-->
      <!--                  <small>{{$t('general.location')}}</small>-->
      <!--                </label>-->
      <!--                <div class="d-flex w-100">-->
      <!--                  <div>-->
      <!--                                        <span-->
      <!--                                          class="input-group-addon filters__field-icon height-custom-for-filters-in-clients">-->
      <!--                <i class="fa fa-map-marker"></i>-->
      <!--              </span>-->
      <!--                  </div>-->
      <!--                  <div>-->
      <!--                    <select-->
      <!--                      :disabled="isLoadingCLients"-->
      <!--                      @input="locationFilterSelectHandle"-->
      <!--                      class="form-control form-control-sm filters__field height-custom-for-filters-in-clients height-custom-for-filters-in-clients-custom-padding location-filters-in-clients"-->
      <!--                      v-model="filters.location"-->
      <!--                    >-->
      <!--                      <option :selected="true" :value="'All locations'">{{-->
      <!--                        $t('general.allLocations')-->
      <!--                        }}-->
      <!--                      </option>-->
      <!--                      <option v-for="location in locationsList" :value="location.id"-->
      <!--                              :key="location.id">-->
      <!--                        {{ location.name }}-->
      <!--                      </option>-->
      <!--                    </select>-->
      <!--                  </div>-->
      <!--                </div>-->

      <!--              </div>-->
      <!--              &lt;!&ndash;                <div&ndash;&gt;-->
      <!--              &lt;!&ndash;                  v-if="this.filters.location !== 'All locations'"&ndash;&gt;-->
      <!--              &lt;!&ndash;                  class="d-flex justify-content-start align-items-center  with-child-wrapper ">&ndash;&gt;-->
      <!--              &lt;!&ndash;                  <div>&ndash;&gt;-->
      <!--              &lt;!&ndash;                    <input&ndash;&gt;-->
      <!--              &lt;!&ndash;                      type="checkbox"&ndash;&gt;-->
      <!--              &lt;!&ndash;                      v-model="filters.with_childs"&ndash;&gt;-->
      <!--              &lt;!&ndash;                    />&ndash;&gt;-->
      <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->
      <!--              &lt;!&ndash;                  <div>&ndash;&gt;-->
      <!--              &lt;!&ndash;                    <small class="with-child">{{$t('general.withChild')}}</small>&ndash;&gt;-->
      <!--              &lt;!&ndash;                  </div>&ndash;&gt;-->

      <!--              &lt;!&ndash;                </div>&ndash;&gt;-->

      <!--              <div-->
      <!--                class="input-group input-group-sm d-flex align-items-end ml-0 mr-1 align-self-end height-custom-for-filters-in-clients ">-->
      <!--                <div class="d-flex  switcher-with-childs-in-clients-wrapper  align-items-end">-->

      <!--                  <div-->
      <!--                    class="d-flex align-items-end justify-content-start">-->
      <!--                    <Switch-component-->
      <!--                      @change="withChildsFilterSwitchHandle"-->
      <!--                      :disabled="this.filters.location === 'All locations' || isLoadingCLients"-->
      <!--                      v-model="filters.with_childs"-->
      <!--                      class='switcher-with-childs-in-clients'-->

      <!--                    />-->
      <!--                  </div>-->
      <!--                  <div-->
      <!--                    class=" input-group input-group-sm filters__location switcher-with-childs-in-clients-caption">-->
      <!--                    <small class="with-child">{{$t('general.withChild')}}</small>-->
      <!--                  </div>-->

      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->

      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="row filter-block mt-0 mb-0">
        <div class="filter-in-clients">
          <div>
            <!--            <div class="filters__search">-->
            <!--              <div class="input-group input-group-sm filters__input filters__input&#45;&#45;clients">-->
            <!--              <span class="input-group-addon">-->
            <!--                <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinner"></i>-->
            <!--                <i class="fa fa-search" v-else></i>-->
            <!--              </span>-->
            <!--                <input-->
            <!--                  id="search"-->
            <!--                  name="search"-->
            <!--                  :placeholder="$t('general.search')"-->
            <!--                  @keyup.13="searchInputHandler(searchValue)"-->
            <!--                  class="form-control"-->
            <!--                  v-model="searchValue"-->
            <!--                  @input="searchInputHandler(searchValue)"-->
            <!--                />-->
            <!--                <span class="clear-input text-muted">-->
            <!--                <i class="fa fa-remove" v-if="searchValue && !searchSpinner"-->
            <!--                   @click="searchInputHandler('')"></i>-->
            <!--              </span>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="filters__multiple">
              <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">
                <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter == 'other' }">
                  <input
                    type="radio"
                    :disabled="isLoadingCLients"
                    name="typeFilter"
                    @click="setFilter('typeFilter', 'other')"
                  />
                  {{ $t('clients.other') }}
                </label>
                <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter == 'camera' }">
                  <input
                    type="radio"
                    :disabled="isLoadingCLients"
                    name="typeFilter"
                    @click="setFilter('typeFilter', 'camera')"
                  />
                  {{ $t('clients.camera') }}
                </label>
                <label class="btn btn btn-outline-info" :class="{ active: filters.typeFilter == 'wired' }">
                  <input
                    type="radio"
                    :disabled="isLoadingCLients"
                    name="typeFilter"
                    @click="setFilter('typeFilter', 'wired')"
                  />
                  {{ $t('clients.wired') }}
                </label>
              </div>

              <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">
                <label class="btn btn btn-outline-info" :class="{ active: filters.statusFilter == 'CONNECTED' }">
                  <input
                    type="radio"
                    :disabled="isLoadingCLients"
                    name="statusFilter"
                    @click="setFilter('statusFilter', 'CONNECTED')"
                  />
                  {{ $t('clients.connected') }}
                </label>
                <label class="btn btn btn-outline-info" :class="{ active: filters.statusFilter == 'DISCONNECTED' }">
                  <input
                    type="radio"
                    name="statusFilter"
                    :disabled="isLoadingCLients"
                    @click="setFilter('statusFilter', 'DISCONNECTED')"
                  />
                  {{ $t('clients.disconnected') }}
                </label>
              </div>

              <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">
                <label class="btn btn btn-outline-info" :class="{ active: filters.frequencyFilter == '2.4' }">
                  <input
                    :disabled="isLoadingCLients || filters.typeFilter == 'wired'"
                    type="radio"
                    name="frequencyFilter"
                    @click="setFilter('frequencyFilter', '2.4')"
                  />
                  2.4 {{ $t('aps.ghz') }}
                </label>
                <label class="btn btn btn-outline-info" :class="{ active: filters.frequencyFilter == '5' }">
                  <input
                    :disabled="isLoadingCLients || filters.typeFilter == 'wired'"
                    type="radio"
                    name="frequencyFilter"
                    @click="setFilter('frequencyFilter', '5')"
                  />
                  5 {{ $t('aps.ghz') }}
                </label>
              </div>

              <div class="btn-group btn-group-sm float-left" data-toggle="button">
                <label class="btn btn btn-outline-info" :class="{ active: filters.authorizedFilter == 'true' }">
                  <input
                    :disabled="isLoadingCLients"
                    type="radio"
                    name="authorizedFilter"
                    @click="setFilter('authorizedFilter', 'true')"
                  />
                  {{ $t('clients.authorized') }}
                </label>
              </div>
            </div>
          </div>
          <!--          <div class="health-status-buttons-block">-->
          <!--            <transition name="fade-in-clients">-->
          <!--              <div v-if="filters.statusFilter === 'CONNECTED'">-->
          <!--                <div>-->
          <!--                  {{ $t('clients.tableTab_healthScore') }}-->
          <!--                  <info-->
          <!--                    @clicked="showHealthStatusInfoModal"-->
          <!--                    class=""-->
          <!--                    :content="$t('clients.healthScoresInfoTooltip')"-->
          <!--                  />-->
          <!--                  <transition name="fade-in-clients">-->
          <!--                    <span-->
          <!--                      class="health-status-buttons-info mt-0 text-muted"-->
          <!--                      v-if="filters.statusFilter !== 'CONNECTED'"-->
          <!--                    >-->
          <!--                      {{ $t('clients.healthStatusButtonsInfo') }}-->
          <!--                    </span>-->
          <!--                  </transition>-->
          <!--                </div>-->
          <!--                <div class="filters__multiple">-->
          <!--                  <div class="btn-group btn-group-sm float-left" data-toggle="button">-->
          <!--                    <label-->
          <!--                      class="btn btn btn-outline-info"-->
          <!--                      :class="{-->
          <!--                        active: filters.healthStatusFilter == 'Poor',-->
          <!--                        disabled: filters.statusFilter !== 'CONNECTED'-->
          <!--                      }"-->
          <!--                    >-->
          <!--                      <input-->
          <!--                        :disabled="isLoadingCLients || filters.statusFilter !== 'CONNECTED'"-->
          <!--                        type="radio"-->
          <!--                        name="healthStatusFilterPoor"-->
          <!--                        @click="setFilter('healthStatusFilter', 'Poor')"-->
          <!--                      />-->
          <!--                      {{ $t('clients.healthPoor') }}-->
          <!--                    </label>-->
          <!--                    <label-->
          <!--                      class="btn btn btn-outline-info"-->
          <!--                      :class="{-->
          <!--                        active: filters.healthStatusFilter == 'Fair',-->
          <!--                        disabled: filters.statusFilter !== 'CONNECTED'-->
          <!--                      }"-->
          <!--                    >-->
          <!--                      <input-->
          <!--                        :disabled="isLoadingCLients || filters.statusFilter !== 'CONNECTED'"-->
          <!--                        type="radio"-->
          <!--                        name="healthStatusFilterFair"-->
          <!--                        @click="setFilter('healthStatusFilter', 'Fair')"-->
          <!--                      />-->
          <!--                      {{ $t('clients.healthFair') }}-->
          <!--                    </label>-->
          <!--                    <label-->
          <!--                      class="btn btn btn-outline-info"-->
          <!--                      :class="{-->
          <!--                        active: filters.healthStatusFilter == 'Good',-->
          <!--                        disabled: filters.statusFilter !== 'CONNECTED'-->
          <!--                      }"-->
          <!--                    >-->
          <!--                      <input-->
          <!--                        :disabled="isLoadingCLients || filters.statusFilter !== 'CONNECTED'"-->
          <!--                        type="radio"-->
          <!--                        name="healthStatusFilterGood"-->
          <!--                        @click="setFilter('healthStatusFilter', 'Good')"-->
          <!--                      />-->
          <!--                      {{ $t('clients.healthGood') }}-->
          <!--                    </label>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </transition>-->
          <!--          </div>-->
        </div>
        <div class="col-lg-4 col-sm-4">
        </div>
      </div>
      <div v-if="isFiltersActive" class="row filter-block mb-1" >
        <div class="">
          <div class="input-group input-group-sm filters__wlan flex-wrap mr-1 mt-0 pt-0">
            <WButton secondary outline sm
              @click="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showGoBackToClientsStatisticButton" class="col-lg-12 m-0">
      <div v-if="showGoBackToClientsStatisticButton" class="row mt-1 mb-1 pr-q pl-q">
        <div class="col-lg-12">
          <div class="float-right">
            <WButton primary
                @click="goBackToClientsStatistic"
              >
              <i class="fa fa-close"></i>
              {{ $t('clients.goBackToClientsStatistic') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showGoBackToDashboardButton" class="col-lg-12 m-0">
      <div v-if="showGoBackToDashboardButton" class="row mt-1 mb-1 pr-q pl-q">
        <div class="col-lg-12">
          <div class="float-right">
            <WButton primary
                @click="goBackToDashboard"
              >
              {{ $t('dashboard.goBackToDashboard') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showGoBackToCpeButton"   class="col-lg-12 m-0">
      <div v-if="showGoBackToCpeButton" class="row mt-1 mb-1 pr-q pl-q">
        <div class="col-lg-12">
          <div v-if="showGoBackToCpeButtonCPEName" class="float-left">
            <h3>
              {{$t('events.cpeName')}}:&nbsp;{{showGoBackToCpeButtonCPEName}}
            </h3>
          </div>
          <div class="float-right">
            <WButton primary
                @click="goBackToCpe"
              >
              {{ $t('events.goBackToCPE') }}
            </WButton>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 m-0" v-if="showAddButton">
      <div class="client" :class="{ 'edit-visible': editVisible }">
        <div class="client-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <!--                <div class="card" v-if="!isClientsListHasData && showGoBackToCpeButton">-->
                <!--                  <div v-if="isLoadingCLients || isLoadingCompactCpes || isLoadingCompactWlans">-->
                <!--                    <Loader-spinner/>-->
                <!--                  </div>-->
                <!--                  <div class="card-header d-flex align-items-center justify-content-between">-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-clients" v-if="isLoadingCLients"></div>-->
                  <div v-if="isLoadingCLients || isLoadingCompactCpes || isLoadingCompactWlans"><Loader-spinner /></div>
                  <div v-if="isLoadingAll && showGoBackToClientsStatisticButton"><Loader-spinner /></div>
                  <div class="card-block table-responsive">
                    <ClientsTable
                      :items="clientsList || []"
                      :selectedRowClientsIds="[]"
                      :activeRowClientId="clientSelectedMac"
                      :isDataLoading="false"
                      :isDisabled="false"
                      :isOneColMode="Boolean(editVisible)"
                      :isSelectionRowsModeEnabled="false"
                      @on-cell-click="toggleEdit"
                      @on-sort-change="handleSortChange"

                      @on-open-video-modal="handleOpenVideoModal"
                      @on-cpe-name-click="cpeClickHandlerInTable"
                      @on-wlan-name-click="wlanClickHandlerInTable"
                      @on-events-button-click="goToEventsForClients"
                      @on-events-and-rssi-button-click="showRSSIAndEventsModal"
                      @on-disconnect-and-deauthorize-button-click="handleDisconnectAndDeauthorizeClient"
                      >
                      <template v-slot:table-menu>
                        <div class="control-panel">
                          <div class="control-panel__container left">
                          </div>
                          <div class="control-panel__container right">
                            <TableDataDownloader v-if="clientsList.length"
                              :data-for-excel="convertToNewExcelExportModuleFormat(formatToCSV(sortedClientsList.length ? sortedClientsList : clientsList), $t('clients.clients'))"
                              :data="formatToCSV(sortedClientsList.length ? sortedClientsList : clientsList)"
                              :filename="getFileNameForDownloadFiles($t('downloadFilesNames.clients'))"
                              :availableReports="{
                                csv: true,
                                xlsx: {
                                  enabled: true,
                                  autoColumnsWidth: true
                                },
                                pdf: true
                              }"
                            />
                          </div>
                        </div>
                      </template>
                      <!--
                        todo переписать пагинацию на нативную с рефакторингом запросов.
                        Пока что нативная из ClientsTable несовместима с логикой запросов
                      -->
                      <template v-slot:table-actions-bottom>
                        <div v-show="
                            canLoadMore &&
                            clientsList.length &&
                            !isLoadingCLients &&
                            totalResult - clientsList.length
                          "
                          class="card-block text-center p-0"
                        >
                          <WButton link
                            @click="loadMore()"
                          >
                            {{ $t('clients.load') }}
                            <span v-if="limit < totalResult - clientsList.length">
                              {{ limit }}
                            </span>
                            <span v-else>
                              {{ totalResult - clientsList.length }}
                            </span>
                            {{ $t('clients.clientsOutOf') }} {{ totalResult - clientsList.length }}
                          </WButton>
                        </div>
                      </template>
                      <template v-slot:emptystate>
                        <div v-if="
                            !clientsList.length &&
                            filters.statusFilter === 'CONNECTED' &&
                            filters.frequencyFilter === '' &&
                            filters.typeFilter === '' &&
                            filters.healthStatusFilter == '' && !showGoBackToCpeButton
                          "
                          class="emptystate-table-row muted"
                        >
                            {{ $t('clients.noConnectedClientsToDisplay') }}
                            <br />
                            <a href @click.prevent="showAllClients">
                              {{ $t('clients.showAllClients') }}
                            </a>
                        </div>

                        <div v-else-if="
                              !clientsList.length &&
                              filters.statusFilter === 'CONNECTED' &&
                              filters.frequencyFilter === '' &&
                              filters.typeFilter === '' &&
                              filters.healthStatusFilter == '' && showGoBackToCpeButton
                            "
                            class="emptystate-table-row muted"
                          >
                              {{ $t('clients.noConnectedClientsToDisplayWhenCPESelected') }}
                              <br />
                              <a href @click.prevent="showAllClients">
                                {{ $t('clients.showAllClientsWhenCPESelected') }}
                              </a>
                        </div>

                        <div v-else-if="!clientsList.length && !showGoBackToCpeButton"
                          class="emptystate-table-row muted"
                        >
                          {{ $t('general.noDataToDisplay') }}
                        </div>

                        <div v-else-if="!clientsList.length && showGoBackToCpeButton"
                          class="emptystate-table-row muted"
                        >
                          {{ $t('general.noDataToDisplayWhenCPESelected') }}
                        </div>
                      </template>
                    </ClientsTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="client-edit" v-if="editVisible">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info">
                          <div class="edit-header__name">
                            <span class="h4">
                              {{ getFormatMac(clientEditCurrent.mac) }}
                              <span
                                class="clients-camera-button"
                                v-if="
                                  clientEditCurrent.Type === 'camera' &&
                                  clientEditCurrent.data &&
                                  clientEditCurrent.data.rtsp_stream &&
                                  clientEditCurrent.State === 'CONNECTED'
                                "
                                @click="toggleVideoModal(true, clientEditCurrent.data.rtsp_stream)"
                              >
                                <i class="fa fa-video-camera"></i>
                              </span>
                            </span>
                          </div>
                          <div class="edit-header__tags"></div>
                          <WButton secondary outline sm
                            @click="disableEditMode"
                          >
                            <i class="fa fa-close"></i>
                          </WButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-block pt-0">
                  <div class="client-info mt-h">
                    <ul class="client-info-general list-unstyled small mr-h">
                      <!--                      <li class="mb-q">General</li>-->
                      <li v-if="updatedClient.manufacturer">
                        <span class="text-primary">{{ $t('clients.chipManufacturer') }}:</span>
                        <span class="text-muted">
                          <i class :class="{ 'fa ml-q fa-apple': updatedClient.manufacturer === 'Apple' }"></i>
                          {{ updatedClient.manufacturer }}
                        </span>
                      </li>
                      <li v-if="updatedClient.ip">
                        <span class="text-primary">IP:&nbsp;</span>
                        <span class="text-muted">{{ updatedClient.ip }}</span>
                      </li>
                      <li>
                        <span class="text-primary">{{ $t('general.firstConnected') }}:&nbsp;</span>
                        <span class="text-muted">{{ updatedClient.first_connect | moment }}</span>
                      </li>
                      <li>
                        <span v-if="clientEditCurrent.State === 'CONNECTED'" class="text-primary">
                          {{ $t('clients.lastConnected') }}:
                        </span>
                        <span v-if="clientEditCurrent.State === 'DISCONNECTED'" class="text-primary">
                          {{ $t('general.lastDisconnected') }}
                        </span>
                        <span class="text-muted">{{ updatedClient.timestamp | moment }}</span>
                      </li>
                      <li>
                        <span class="text-primary">{{ $t('clients.cpe') }}:&nbsp;</span>
                        <span class="text-muted ml-q" role="button" @click.prevent="cpeClickHandler(updatedClient.cpe_id)">
                          <a @click.prevent.stop="cpeClickHandler(updatedClient.cpe_id)" href="#">{{ cpeNamebyId(updatedClient.cpe_id) || updatedClient.cpe_id }}</a>

                        </span>
                      </li>
                      <li>
                        <span class="text-primary">{{ $t('clients.wlan') }}:&nbsp;</span>
                        <span v-if="wlanNamebyId(updatedClient.wlan_id)" class="text-muted" role="button" @click.prevent="wlanClickHandler(updatedClient.wlan_id)">
                          <a @click.prevent.stop="wlanClickHandler(updatedClient.wlan_id)" href="#">{{ wlanNamebyId(updatedClient.wlan_id) }}</a>
                        </span>
                        <span v-else>{{$t('clients.tableNotFound')}}</span>
                      </li>
                      <li v-if="updatedClient.hasOwnProperty('health_score') && updatedClient.State === 'CONNECTED'">
                        <span class="text-primary">{{ $t('clients.tableTab_healthScore') }}:&nbsp;</span>
                        <span>
                          <span
                            style="font-size: 0.8em;"
                            class="badge mr-q badge-light"
                            :class="getBadgeClassByClientHealthScores(updatedClient.health_score)"
                          >
                            {{ $t(`clients.health${getClientHealthStatus(updatedClient.health_score)}`) }} ({{
                            updatedClient.health_score
                            }}
                            {{ $t('clients.scores') }})
                          </span>
                        </span>
                      </li>
                    </ul>

                    <ul class="client-info-authorization list-unstyled small">
                      <li class="mb-q" v-if="updatedClient.username">{{ $t('clients.authorization') }}</li>

                      <li v-for="(value, name) in clientAuthorization" :key="name" v-if="updatedClient[name]">
                        <span class="text-primary">{{ value }}:&nbsp;</span>
                        <span class="text-muted">{{ updatedClient[name] }}</span>
                      </li>
                    </ul>
                  </div>

                  <div class="d-flex flex-row w-100 justify-content-start m-0 flex-wrap mb-1">

                    <div class="">
                      <span
                                class="input-group-btn  mr-q mt-q"
                                v-tooltip.top-center="{ content: $t('clients.eventsTooltipCaption') }"
                              >
                                <WButton success outline sm
                                  @click.prevent.stop="goToEventsForClients(updatedClient)"
                                >
                          {{ $t('clients.eventsButtonCaption') }}
                        </WButton>
                      </span>
                    </div>
                    <div class="">
                      <span class="input-group-btn mr-q mt-q">
                        <WButton success outline sm
                          @click.prevent.stop="showRSSIAndEventsModal(updatedClient)"
                        >
                          {{ $t('clients.eventsAndRssiButtonCaption') }}
                        </WButton>
                      </span>
                    </div>
                    <div class="" v-if="!isDisable">
                      <span class="input-group-btn  mr-q mt-q">
                        <WButton success outline sm
                                 @click.prevent.stop="handleDisconnectAndDeauthorizeClient(updatedClient)"
                        >
                          {{ $t('clients.disconnectAndDeauthorizeButtonCaption') }}
                        </WButton>
                      </span>
                    </div>

                    <div class="" v-if="isWLCProduct">
                      <span class="input-group-btn  mr-q mt-q">
                        <WButton success outline sm
                                 @click.prevent.stop="handleLogsButtonClick(updatedClient)"
                        >
                          {{ $t('sidebar.logs') }}
                        </WButton>
                      </span>
                    </div>

                  </div>

                  <div class="form-group mt-1" v-if="!isWLCProduct">
                    <label for="role">{{ $t('general.type') }}</label>
                    <select
                      id="role"
                      class="form-control"
                      name="role"
                      v-model="updatedClient.Type"
                      :disabled="isDisable"
                      :class="{ 'select-disabled': isDisable }"
                    >
                      <option value="other" :selected="updatedClient.Type === 'other'">
                        {{ $t('clients.editPanelOther') }}
                      </option>
                      <option value="camera" :selected="updatedClient.Type === 'camera'">
                        {{ $t('clients.editPanelCamera') }}
                      </option>
                      <option value="wired" :selected="updatedClient.Type === 'wired'">
                        {{ $t('clients.editPanelWired') }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group" v-if="updatedClient.Type === 'camera'">
                    <label for="rtsp">{{ $t('clients.editPanelCameraRtspStream') }}</label>
                    <input
                      type="text"
                      name="rtsp"
                      id="rtsp"
                      :placeholder="$t('clients.editPanelCameraRtspStreamLinkPlaceHolder')"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('rtsp') }"
                      v-model="updatedClient.data.rtsp_stream"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('description')" class="help is-danger">{{ errors.first('rtsp') }}</span>
                  </div>

                  <div class="form-group">
                    <label for="description">{{ $t('general.description') }}</label>
                    <input
                      type="text"
                      name="description"
                      id="description"
                      :placeholder="$t('clients.editPanelDescriptionPlaceHolder')"
                      v-validate="'max:30'"
                      class="form-control"
                      :class="{ input: true, 'is-danger': errors.has('description') }"
                      v-model="updatedClient.data.description"
                      :disabled="isDisable"
                    />
                    <span v-show="errors.has('description')" class="help is-danger">
                      {{ errors.first('description') }}
                    </span>
                  </div>
                </div>
                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <WButton success outline
                      v-if="!isDisable"
                      @click="updateClient"
                      :disabled="!enableSaveChanges"
                    >
                      {{ $t('general.save') }}
                    </WButton>
                  </div>
                  <div>
                    <WButton secondary outline
                      @click="disableEditMode"
                    >
                      {{ $t('general.close') }}
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="Video Stream"
      v-model="isVideoModalOpened"
      cancelText="Cancel"
      okText="Ok"
      large
      class="modal-primary"
      @cancel="toggleVideoModal(false)"
      @ok="toggleVideoModal(false)"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">Video Stream</h4>

      <div class="text-center flex-video">
        <video id="videoOutput" autoplay min-height="460px" poster="/static/img/logo_new.svg"></video>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton outline
          @click="toggleVideoModal(false)"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </modal>

    <Modal
      xlarge
      title="ClientRFAndEvents"
      class="modal-info"
      v-model="showingClientRFAndEventsModal"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('events.clientRFAndEventsModalCaption') }}</h4>
      <div slot="modal-body" class="">
        <ClientRFAndEvents
          v-if="showingClientRFAndEventsModal"
          :clientMacAddr="clientMacAddrForRSSIAndEventsModal"
        ></ClientRFAndEvents>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="closeRSSIAndEventsModal"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>

    <Modal title="Show Health Status Info" class="modal-info" v-model="showingHealthStatusInfoModal" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('clients.healthStatusModalInfoTitle') }}</h4>
      <div>
        <p>{{ $t('clients.healthStatusModalInfoCaption') }}:</p>
      </div>
      <div v-if="isRuLocale">
        <ul>
          <li>
            <b>{{ $t('clients.healthPoor') }}</b>
            : В диапазоне от 1 до 3 баллов
          </li>
          <li>
            <b>{{ $t('clients.healthFair') }}</b>
            : В диапазоне от 4 до 7 баллов
          </li>
          <li>
            <b>{{ $t('clients.healthGood') }}</b>
            : В диапазоне от 8 до 10 баллов
          </li>
        </ul>
        <p>Баллы выставляются на основе клиентского RSSI и SNR</p>
      </div>
      <div v-else>
        <ul>
          <li>
            <b>{{ $t('clients.healthPoor') }}</b>
            : Health score range from 1 to 3
          </li>
          <li>
            <b>{{ $t('clients.healthFair') }}</b>
            : Health score range from 4 to 7
          </li>
          <li>
            <b>{{ $t('clients.healthGood') }}</b>
            : Health score range from 8 to 10
          </li>
        </ul>
        <p>Points are awarded based on client RSSI and SNR</p>
      </div>
      <div slot="modal-footer" class="modal-footer modal-btn-right">
        <WButton secondary
          @click="showingHealthStatusInfoModal = false"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { createNamespacedHelpers } from 'vuex';
import { WSADDR_URL } from '@/config';

import commonService from '@/services/commonService';
import clientService from '@/services/clientService';
import cpeService from '@/services/cpeService';
import wlanService from '@/services/wlanService';

import Filters from '@/filters';
import Modal from '@/components/Modal.vue';
import helpers from '@/helpers';
import Info from '@/components/Universal/info-icon.vue';
import ClientRFAndEvents from '@/components/ClientRFAndEvents.vue';
import SwitchComponent from '@/components/Universal/Switch-component.vue';
import MultiselectWrapperForUseInFilters
  from '@/components/general/filters/MultiselectWrapperForUseInFilters.vue';
import locationService from '@/services/locationService';
import SelectComponentV2 from '@/components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '@/components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import clientDisconnectAndDeauthorizeService from '../../services/clientDisconnectAndDeauthorizeService';

import {
  ClientsTable
} from './components';

import { intervalUpdateModeMixin, debounceMixin, searchBarMixin } from '@/mixins';

Vue.use(require('vue-moment'));

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'Clients',
  components: {
    Modal,
    Info,
    ClientRFAndEvents,
    SwitchComponent,
    Multiselect,
    MultiselectWrapperForUseInFilters,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors,
    ClientsTable
  },
  mixins: [
    intervalUpdateModeMixin,
    debounceMixin,
    searchBarMixin
  ],
  data() {
    return {
      abortLastSearchValueChangingQuery: false,
      showLocationFilter: true,
      locationInFiltersData: [],
      showGoBackToDashboardButton: false,
      showGoBackToClientsStatisticButton: false,
      showGoBackToCpeButton: false,
      showGoBackToCpeButtonCPEName: '',
      selectedWlanInMultiselector: false,
      selectedCpeInMultiselector: false,
      cpesCompactListForFilter: [],
      wlansCompactListForFilter: [],
      clientMacAddrForRSSIAndEventsModal: '',
      showingClientRFAndEventsModal: false,
      showingHealthStatusInfoModal: false,
      limit: 100,
      offset: 0,
      delayTimer: null,
      searchValue: '',
      searchSpinner: '',
      totalResult: false,
      canLoadMore: true,
      isVideoModalOpened: false,
      enableSaveChanges: false,
      filters: {
        typeFilter: '',
        statusFilter: 'CONNECTED',
        frequencyFilter: '',
        query: '',
        authorizedFilter: '',
        healthStatusFilter: '',
        location: 'All locations',
        with_childs: false,
        cpe_id: '',
        wlan_id: ''
      },
      updatedClient: {
        data: {
          description: '',
          rtsp_stream: 'rtsp_stream'
        },
        Type: ''
      },
      clientAuthorization: {
        username: 'Username',
        ua_type: 'Type',
        ua_device: 'Device',
        os: 'OS',
        os_version: 'OS version',
        useragent: 'Useragent'
      },
      sortedClientsList: []
    };
  },
  computed: {
    ...localeMapGetters(['isRuLocale']),
    ...WLCProductMapGetters(['isWLCProduct']),
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    isClientsListHasData() {
      try {
        if (this.filterItems(this.clientsList) && Array.isArray(this.filterItems(this.clientsList)) &&
        this.filterItems(this.clientsList).length) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    showAddButton() {
      return this.$route.name === 'Clients';
    },
    editVisible() {
      // todo отвечает за открытие боковой панели редактирования
      return typeof this.$store.state.editClientIndex === 'string' && this.$store.state.editClientIndex.length;
    },
    clientsList() {
      return this.$store.state.clientsList;
    },
    clientSelectedMac() {
      return this.$store.state.editClientMac;
    },
    isLoadingCLients() {
      return this.$store.state.loadingClients;
    },
    isLoadingCompactCpes() {
      return this.$store.state.getCpesCompactForFilterRequestExecuting;
    },
    isLoadingCompactWlans() {
      return this.$store.state.getWlansCompactForFilterRequestExecuting;
    },
    isLoadingAll() {
      return this.isLoadingCLients || this.isLoadingCompactCpes || this.isLoadingCompactWlans
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    clientEditCurrent() {
      const { clientsList } = this.$store.state;
      let currentClient;
      clientsList.forEach((client) => {
        if (client.mac == this.$store.state.editClientMac) {
          currentClient = client;
        }
      });
      if (currentClient.Type === 'other') {
        currentClient.data.rtsp_stream = '';
      }
      this.updatedClient = JSON.parse(JSON.stringify(currentClient));
      return currentClient;
    },
    isFiltersActive() {
      return (
        this.filters.typeFilter !== '' ||
        this.filters.statusFilter !== 'CONNECTED' ||
        this.filters.frequencyFilter !== '' ||
        this.filters.query !== '' ||
        this.filters.authorizedFilter !== '' ||
        this.filters.healthStatusFilter !== '' ||
        this.filters.location !== 'All locations' ||
        this.filters.cpe_id !== '' ||
        this.filters.wlan_id !== '' || this.searchValue !== ''
      );
    }
  },
  watch: {
    searchValue(newSearchQuery) {
      this.debounce(async () => {
        // todo refactor
        // хак чтобы при переходах на страницу с других страниц (dashboard), открывалась панель настройки  и отображалась кнопка редиректа на пред. страницу
        if (this.abortLastSearchValueChangingQuery) {
          this.showGoBackToClientsStatisticButton = false;
          this.showGoBackToDashboardButton = false;
          this.abortLastSearchValueChangingQuery = false;
        }

        if (this.showGoBackToClientsStatisticButton || this.showGoBackToDashboardButton) {
          this.abortLastSearchValueChangingQuery = true;

          return;
        }

        // хак закончился

        if (!newSearchQuery) {
          return;
        }

        if (this.setSearchBarQuery) {
          this.setSearchBarQuery(newSearchQuery);
        }

        this.searchInputHandler(newSearchQuery);
      }, 1000);
    },
    updatedClient: {
      handler(val, oldVal) {
        if (!oldVal) {
          this.enableSaveChanges = false;
        } else if (val && oldVal && val.id !== oldVal.id) {
          this.enableSaveChanges = false;
        } else if (!val) {
          this.enableSaveChanges = false;
        } else {
          this.enableSaveChanges = true;
        }
      },
      deep: true
    },
    isLoadingAll: {
      handler(val, oldVal) {
        try {
          if (oldVal === true && val === false && this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('clientIdFormClientsStatisic')) {
            // после загрузки списка клиентов если ранее  перешили в раздел Клиентов из статистики по Клиентам с передачей определного клиента для октрытия открываем его в панели редактироваания
            if (Array.isArray(this.clientsList) && this.clientsList.length > 0 && this.clientsList.find(client => {
              return client.mac === this.$route.params.clientIdFormClientsStatisic;
            })) {
              this.$nextTick(() => {
                this.$store.commit('setClientEditIndex', '0');
                this.$store.commit('setClientEditMac', this.$route.params.clientIdFormClientsStatisic ?? '');
                // и удаляем чтобы этот вотчер больше не отрабатывал если список wlans обновляется (и isLoadingAll меняет состояние)
                delete this.$route.params.clientIdFormClientsStatisic;
              });
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  methods: {
    /**
     * Хендлер, отрабатывающий при нажатии на кнопку Логи в панельке редактирования клиента
     *
     * @param {Object} clientObject
     */
    handleLogsButtonClick(clientObject) {
      this.redirectToLogsPageAndPassMac(clientObject);
    },
    /**
     * Перенаправляет юзера на страницу с логами, передавая в параметрах MAC,
     * чтобы показать там логи по конкретному клиенту
     *
     * @param {Object} clientObject
     */
    redirectToLogsPageAndPassMac(clientObject) {
      const clientMac = clientObject.mac_addr;
      this.$router.push({ name: 'Logs', params: { clientMacAddr: clientMac } });
    },
    /**
     * Используется для деавторизации и дисконнекта клиента при клике на соответсвующие кнопки
     *
     * @param {Object} clientObject
     */
    handleDisconnectAndDeauthorizeClient(clientObject) {
      clientDisconnectAndDeauthorizeService.clientDisconnectAndDeauthorize(clientObject,
        {
          onSuccess: () => {
            if (this.editVisible) {
              this.disableEditMode();
            }
            clientService.refreshClientsV2(this, 'spinner');
          },
          successMessage: this.$t('clients.disconnectAndDeauthorizeSuccessMsg')
        });
    },
    handleSortChange(data) {
      const { sortedTableRows } = data;

      const sortedClientsList = sortedTableRows
        .map(tableRow => tableRow.nativeData);

      this.sortedClientsList = sortedClientsList;
    },
    applySelectedInHeaderLocation() {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    },
    wlanClickHandlerInTable(wlanId) {
      // if (this.editVisible) {
      //   return
      // }
      this.wlanClickHandler(wlanId)
    },
    wlanClickHandler(wlanId) {
      if (!wlanId) {
        return;
      }
      this.saveComponentStateDataForRestore();
      this.$router.push({ name: 'Wlans', params: { wlanIdFormClients: wlanId } });
      // console.log(cpeId)
    },
    cpeClickHandlerInTable(cpeId) {
      // if (this.editVisible) {
      //   return
      // }
      this.cpeClickHandler(cpeId)
    },
    cpeClickHandler(cpeId) {
      if (!cpeId) {
        return;
      }
      this.saveComponentStateDataForRestore();
      this.$router.push({ name: 'Aps', params: { cpeIdFormClients: cpeId } });
      // console.log(cpeId)
    },
    saveComponentStateDataForRestore() {
      const elementWithScrollBar = document.getElementById('container-fluid');
      const scrollValue = elementWithScrollBar.scrollTop;

      // из стора
      const clientsList = JSON.parse(JSON.stringify(this.$store.state.clientsList));
      const editClientIndex = JSON.parse(JSON.stringify(this.$store.state.editClientIndex));
      const editClientMac = JSON.parse(JSON.stringify(this.$store.state.editClientMac));
      const wlansList = JSON.parse(JSON.stringify(this.$store.state.wlansList))
      const cpesList = JSON.parse(JSON.stringify(this.$store.state.cpesList))


      // из стейта компонента
      const filters = JSON.parse(JSON.stringify(this.filters));
      const wlansCompactListForFilter = JSON.parse(JSON.stringify(this.wlansCompactListForFilter));
      const cpesCompactListForFilter = JSON.parse(JSON.stringify(this.cpesCompactListForFilter));
      const locationInFiltersData = JSON.parse(JSON.stringify(this.locationInFiltersData));
      const selectedCpeInMultiselector = JSON.parse(JSON.stringify(this.selectedCpeInMultiselector));
      const selectedWlanInMultiselector = JSON.parse(JSON.stringify(this.selectedWlanInMultiselector));

      const offset = this.offset;
      const totalResult = this.totalResult;
      const canLoadMore = this.canLoadMore;
      const sortByFilter = this.sortByFilter;
      const sortMode = this.sortMode;


      const searchValue = this.searchValue;



      const clientsComponentStateDataForRestoreInClientsPage = {
        clientsList,
        filters,
        wlansCompactListForFilter,
        cpesCompactListForFilter,
        editClientIndex,
        editClientMac,
        searchValue,
        locationInFiltersData,
        selectedCpeInMultiselector,
        selectedWlanInMultiselector,
        wlansList,
        cpesList,
        offset,
        totalResult,
        canLoadMore,
        sortByFilter,
        sortMode,
        scrollValue


      };
      localStorage.setItem('clientsComponentStateDataForRestoreInClientsPage',
        JSON.stringify(clientsComponentStateDataForRestoreInClientsPage));
    },
    loadComponentStateDataForRestoreAndRestore() {
      const clientsComponentStateDataForRestoreInClientsPage = JSON.parse(localStorage.getItem('clientsComponentStateDataForRestoreInClientsPage'));
      if (clientsComponentStateDataForRestoreInClientsPage) {
        // восстанавливаем в стейт компонента
        if (!!clientsComponentStateDataForRestoreInClientsPage.filters) {
          this.$set(this, 'filters',
            JSON.parse(JSON.stringify(clientsComponentStateDataForRestoreInClientsPage.filters)))
        }

        if (!!clientsComponentStateDataForRestoreInClientsPage.wlansCompactListForFilter) {
          this.$set(this, 'wlansCompactListForFilter',
            JSON.parse(JSON.stringify(clientsComponentStateDataForRestoreInClientsPage.wlansCompactListForFilter)))
        }

        if (!!clientsComponentStateDataForRestoreInClientsPage.cpesCompactListForFilter) {
          this.$set(this, 'cpesCompactListForFilter',
            JSON.parse(JSON.stringify(clientsComponentStateDataForRestoreInClientsPage.cpesCompactListForFilter)))
        }

        if (!!clientsComponentStateDataForRestoreInClientsPage.locationInFiltersData) {
          this.$set(this, 'locationInFiltersData',
            JSON.parse(JSON.stringify(clientsComponentStateDataForRestoreInClientsPage.locationInFiltersData)))
        }

        if (!!clientsComponentStateDataForRestoreInClientsPage.selectedCpeInMultiselector) {
          this.$set(this, 'selectedCpeInMultiselector',
            JSON.parse(JSON.stringify(clientsComponentStateDataForRestoreInClientsPage.selectedCpeInMultiselector)))
        }

        if (!!clientsComponentStateDataForRestoreInClientsPage.selectedWlanInMultiselector) {
          this.$set(this, 'selectedWlanInMultiselector',
            JSON.parse(JSON.stringify(clientsComponentStateDataForRestoreInClientsPage.selectedWlanInMultiselector)))
        }

        this.searchValue = clientsComponentStateDataForRestoreInClientsPage.searchValue ?? '';

        this.offset = clientsComponentStateDataForRestoreInClientsPage.offset;
        this.totalResult = clientsComponentStateDataForRestoreInClientsPage.totalResult;
        this.canLoadMore = clientsComponentStateDataForRestoreInClientsPage.canLoadMore;
        this.sortByFilter = clientsComponentStateDataForRestoreInClientsPage.sortByFilter;
        this.sortMode = clientsComponentStateDataForRestoreInClientsPage.sortMode;

        // console.log(clientsComponentStateDataForRestoreInClientsPage.cpesList)
        this.$store.commit('setWlansList', clientsComponentStateDataForRestoreInClientsPage.wlansList ?? []);
        this.$store.commit('setCpesList', clientsComponentStateDataForRestoreInClientsPage.cpesList ?? []);


        this.$nextTick(() => {
          this.$store.commit('setClientsList', clientsComponentStateDataForRestoreInClientsPage.clientsList ?? []);
          this.$nextTick(() => {
            this.$store.commit('setClientEditMac', clientsComponentStateDataForRestoreInClientsPage.editClientMac ?? '');
            this.$nextTick(() => {
              this.$store.commit('setClientEditIndex', clientsComponentStateDataForRestoreInClientsPage.editClientIndex ?? 0);
              this.$nextTick(() => {
                if (clientsComponentStateDataForRestoreInClientsPage.scrollValue) {
                  const elementWithScrollBar = document.getElementById('container-fluid');
                  if (elementWithScrollBar) {
                    elementWithScrollBar.scrollTop = clientsComponentStateDataForRestoreInClientsPage.scrollValue
                  }
                }
              })
            })
          });
        })

      }
    },
    removeComponentStateDataForRestore() {
      localStorage.removeItem('clientsComponentStateDataForRestoreInClientsPage');
    },
    checkComponentStateDataForRestore() {
      return !!localStorage.getItem('clientsComponentStateDataForRestoreInClientsPage');
    },
    goBackToClientsStatistic() {
      this.$router.push({ name: 'Statclients', params: { goBackFromClients: true } })
     },
    goBackToCpe() {
      // console.log('go back to cpe');
      this.$router.push({name: 'Aps', params: {goBackFromClients: true}})
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    convertToNewExcelExportModuleFormat(inputDataForOldExcelModule, nameForExcelList){
      return helpers.convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, nameForExcelList)
    },
    initializeLocationFilterWhenGoFromCPE(locationId) {
      try {
        if (locationId !== '') {
          this.filters.location = locationId;
          this.locationInFiltersData = commonService.locationObjbyId(locationId);
        } else {
          this.filters.location = 'All locations';
          this.filters.with_childs = true;
          this.locationInFiltersData = [];
        }
      } catch (e) {
        console.log(e);
        this.filters.location = 'All locations';
        this.filters.with_childs = true;
        this.locationInFiltersData = [];
      }
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshClientsListQuery()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     typeFilter: '',
    //     statusFilter: 'CONNECTED',
    //     frequencyFilter: '',
    //     query: '',
    //     authorizedFilter: '',
    //     healthStatusFilter: '',
    //     location: 'All locations',
    //     with_childs: false,
    //     cpe_id: '',
    //     wlan_id: ''
    //   };
    //   this.disableEditMode();
    //   this.searchValue = '';
    //   // this.filters.location = this.userBaseLocation;
    //   this.selectedWlanInMultiselector = false;
    //   this.selectedCpeInMultiselector = false;
    //   this.refreshClientsListQuery();
    // },
    resetAllFilters() {
     // this.locationInFiltersData = [];
     // this.toggleShowLocationFilter();
      this.filters = {
        typeFilter: '',
        statusFilter: 'CONNECTED',
        frequencyFilter: '',
        query: '',
        authorizedFilter: '',
        healthStatusFilter: '',
        // location: 'All locations',
        with_childs: false,
        cpe_id: '',
        wlan_id: ''
      };

      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.disableEditMode();
      this.resetFilterQuery();
      // this.filters.location = this.userBaseLocation;
      this.selectedWlanInMultiselector = false;
      this.selectedCpeInMultiselector = false;
      this.refreshClientsListQuery();
    },
    // cpeObjbyId(id) {
    //   const cpesList = this.cpesCompactListForFilter;
    //   let foundCpe;
    //
    //   for (const cpe of cpesList) {
    //     if (cpe.id === id) {
    //       foundCpe = cpe;
    //       break;
    //     }
    //   }
    //
    //   return foundCpe;
    // },
    // selectWlanInWlanFiltersHandler(value) {
    //   // console.log(value)
    //   // console.log(value, id);
    //   if (value === null){
    //     this.filters.wlan_id = '';
    //   } else {
    //     this.filters.wlan_id = value.id;
    //   }
    //   this.refreshClientsListQuery();
    // },
    selectWlanInWlanFiltersHandler(value) {
      this.disableEditMode();
      // console.log(value)
      // console.log(value)
      // console.log(value, id);
      if (value.length === 0 || value === null) {
        this.filters.wlan_id = '';
      } else {
        this.filters.wlan_id = value[0].id;
      }
      this.refreshClientsListQuery();
    },
    // selectCpeInCpeFiltersHandler(value) {
    //   if (value === null){
    //     this.filters.cpe_id = '';
    //   } else {
    //     this.filters.cpe_id = value.id;
    //   }
    //   this.refreshClientsListQuery();
    // },
    selectCpeInCpeFiltersHandler(value) {
      this.disableEditMode();
      if (value.length === 0 || value === null) {
        this.filters.cpe_id = '';
      } else {
        this.filters.cpe_id = value[0].id;
      }
      this.showGoBackToCpeButton = false;
      this.refreshClientsListQuery();
    },
    // locationFilterSelectHandle() {
    //   this.$nextTick(() => {
    //     this.refreshClientsListQuery();
    //   });
    // },
    locationFilterSelectHandle() {
      this.disableEditMode();
      // хак для safari, вместо $nextTick
      setTimeout(() => {
        this.refreshClientsListQuery();
      }, 100);
    },
    withChildsFilterSwitchHandle() {
      this.disableEditMode();
      this.refreshClientsListQuery();
    },
    showRSSIAndEventsModal(client) {
      // console.log(client);
      this.clientMacAddrForRSSIAndEventsModal = client.mac_addr;
      this.$nextTick(() => {
        this.showingClientRFAndEventsModal = true;
      });
    },
    closeRSSIAndEventsModal() {
      this.showingClientRFAndEventsModal = false;
      this.$nextTick(() => {
        this.clientMacAddrForRSSIAndEventsModal = '';
      });
    },
    goBackToDashboard(){
      this.$router.push({name: 'Dashboard'})
    },
    goToEventsForClients(client) {
      // console.log(client);
      if (Object.prototype.hasOwnProperty.call(client, 'mac_addr')) {
        // console.log(client.mac_addr);
        this.saveComponentStateDataForRestore();
        this.$router.push({ name: 'Events', params: { clientMacAddr: client.mac_addr } });
      }
    },
    showHealthStatusInfoModal() {
      this.showingHealthStatusInfoModal = true;
    },
    getClientHealthStatus(healthScore) {
      //  Red—Poor client devices. Health score range is 1 to 3.
      //  Orange—Fair client devices. Health score range is 4 to 7.
      //  Green—Good client devices. Health score range is 8 to 10
      //  Gray—Inactive client devices. Health score is 0.
      if (healthScore === 0) {
        return 'Inactive';
      } if (healthScore >= 1 && healthScore <= 3) {
        return 'Poor';
      } if (healthScore >= 4 && healthScore <= 7) {
        return 'Fair';
      } if (healthScore >= 8 && healthScore <= 10) {
        return 'Good';
      }
      return '';
    },
    getBadgeClassByClientHealthScores(healthScore) {
      const healthStatus = this.getClientHealthStatus(healthScore);
      if (healthStatus === 'Inactive') {
        return 'health-status-badge-inactive';
      }
      if (healthStatus === 'Poor') {
        return 'health-status-badge-poor';
      }
      if (healthStatus === 'Fair') {
        return 'health-status-badge-fair';
      }
      if (healthStatus === 'Good') {
        return 'health-status-badge-good';
      }
      return '';
    },
    toggleEdit(rowClientsTableData) {
      const { originalIndex, nativeData: { mac } } = rowClientsTableData;

      // todo refactor
      this.$store.commit('setClientEditIndex', String(originalIndex));
      this.$store.commit('setClientEditMac', mac);
    },
    resetFilterQuery() {
      this.searchValue = '';

      if (this.resetSearchBarQuery) {
        this.resetSearchBarQuery();
      }
    },
    searchInputHandler(value) {
      this.showGoBackToClientsStatisticButton = false
      this.disableEditMode();
      this.searchValue = value;
      this.searchSpinner = true;
      clientService.refreshClientsV2(this, 'spinner');
    },
    searchByCpeIdHandler(value) {
      this.disableEditMode();
      this.searchValue = value;
      this.searchSpinner = true;
      clientService.refreshClientsV2(this, 'spinner');
    },
    refreshClientsListQuery() {
      this.canLoadMore = true;
      this.offset = 0;
      clientService.refreshClientsV2(this, 'spinner');
    },
    showAllClients() {
      this.filters.statusFilter = '';
      this.canLoadMore = true;
      clientService.refreshClientsV2(this, 'spinner');
    },
    loadMore() {
      this.offset++;
      clientService.getPaginatedClientsV2(this, this.limit, this.offset);
    },
    toggleTableColumn(value, key) {
      // deprecated and deleted setTableSettings
      // this.$store.commit('setTableSettings', {
      //   tableName: 'client',
      //   key,
      //   value
      // });
    },
    handleOpenVideoModal(rtspStream) {
      this.toggleVideoModal(true, rtspStream);
    },
    toggleVideoModal(state, link) {
      this.isVideoModalOpened = state;

      if (state) {
        this.startVideo(link);
      } else {
        this.stopVideo();
      }
    },
    stopVideo() {
      if (this.webRtcPeer) {
        this.webRtcPeer.dispose();
        this.webRtcPeer = null;
      }
      if (this.pipeline) {
        this.pipeline.release();
        this.pipeline = null;
      }
    },
    startVideo(link) {
      const args = getopts(location.search, {
        default: {
          ws_uri: this.WSADDR_URL,
          ice_servers: undefined
        }
      });

      if (args.ice_servers) {
        kurentoUtils.WebRtcPeer.prototype.server.iceServers = JSON.parse(args.ice_servers);
      } else {
      }

      const videoOutput = document.getElementById('videoOutput');
      const address = {};
      //                address.value = 'rtsp://admin:admin@10.30.40.209:554/cam/realmonitor?channel=1&subtype=0';
      address.value = link;
      let pipeline;
      let webRtcPeer;
      const options = {
        remoteVideo: videoOutput
      };

      address.disabled = true;
      showSpinner(videoOutput);

      webRtcPeer = this.webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, (error) => {
        if (error) {
          return console.error(error);
        }
        webRtcPeer.generateOffer(onOffer);
        webRtcPeer.peerConnection.addEventListener('iceconnectionstatechange', (event) => {
          if (webRtcPeer && webRtcPeer.peerConnection) {
          }
        });
      });

      function setIceCandidateCallbacks(webRtcEndpoint, webRtcPeer, onError) {
        webRtcPeer.on('icecandidate', (candidate) => {
          candidate = kurentoClient.register.complexTypes.IceCandidate(candidate);

          webRtcEndpoint.addIceCandidate(candidate, onError);
        });
        webRtcEndpoint.on('OnIceCandidate', (event) => {
          const { candidate } = event;

          webRtcPeer.addIceCandidate(candidate, onError);
        });
      }

      function getopts(args, opts) {
        const result = opts.default || {};
        args.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
          result[$1] = $3;
        });

        return result;
      }

      function onOffer(error, sdpOffer) {
        if (error) return onError(error);

        const that = this;
        kurentoClient(args.ws_uri, (error, kurentoClient) => {
          if (error) return onError(error);

          kurentoClient.create('MediaPipeline', (error, p) => {
            if (error) return onError(error);

            pipeline = that.pipeline = p;

            pipeline.create('PlayerEndpoint', { uri: address.value }, (error, player) => {
              if (error) return onError(error);

              pipeline.create('WebRtcEndpoint', (error, webRtcEndpoint) => {
                if (error) return onError(error);

                setIceCandidateCallbacks(webRtcEndpoint, webRtcPeer, onError);

                webRtcEndpoint.processOffer(sdpOffer, (error, sdpAnswer) => {
                  if (error) return onError(error);

                  webRtcEndpoint.gatherCandidates(onError);

                  webRtcPeer.processAnswer(sdpAnswer);
                });

                player.connect(webRtcEndpoint, (error) => {
                  if (error) return onError(error);

                  player.play((error) => {
                    if (error) return onError(error);
                  });
                });
              });
            });
          });
        });
      }

      function onError(error) {
        console.warn('video error');
      }

      function showSpinner() {
        for (let i = 0; i < arguments.length; i++) {
          arguments[i].poster = 'static/img/transparent-1px.png';
          arguments[i].style.background = "center transparent url('static/img/spinner.gif') no-repeat";
        }
      }

      function hideSpinner() {
        console.warn('Hide spinner must works too');
      }
    },
    updateClient() {
      this.$validator
        .validateAll({
          description: this.updatedClient.data.description
        })
        .then((result) => {
          if (result) {
            clientService.updateClient(this);
            this.disableEditMode();
          }
        });
    },
    // todo эти все фильтры не нужны
    // это все на бэке делается
    // в будущем отрефакторить и удалить filterItems
    filterItems(items) {
      let filteredClients = items;
      if (filteredClients) {
        filteredClients = Filters.clientTypeFilters(filteredClients, this.filters.typeFilter);
        filteredClients = Filters.clientFrequencyFilters(filteredClients, this.filters.frequencyFilter);
        filteredClients = Filters.clientSearchQuery(filteredClients, this.filters.query);
      }
      this.filteredClients = filteredClients;

      return filteredClients;
    },
    setFilter(filterName, value) {
      this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
      } else {
        this.filters[filterName] = '';
      }
      if (filterName === 'typeFilter' && value === 'wired') {
        this.filters.frequencyFilter = '';
      }
      this.refreshClientsListQuery();
    },
    disableEditMode() {
      this.$store.commit('setClientEditIndex', null);
      this.$store.commit('setClientEditMac', null);
    },
    cpeNamebyId(id) {
      const cpeNamebyId = commonService.cpeNamebyId(id);
      return cpeNamebyId;
    },
    wlanNamebyId(id) {
      const wlanNamebyId = commonService.wlanNamebyId(id);
      return wlanNamebyId;
    },
    getFormatMac(mac) {
      return helpers.getFormatMac(mac);
    },
    // formatToCSV(clients) {
    //   if (!clients) {
    //     return [];
    //   }
    //
    //   const csvClients = JSON.parse(JSON.stringify(clients));
    //
    //   return csvClients.map((client) => ({
    //     Type: client.Type ? client.Type : '',
    //     MAC: client.mac ? this.getFormatMac(client.mac) : '',
    //     IP: client.ip ? client.ip : '',
    //     Authorized: !!client.username,
    //     Description: client.data.description ? client.data.description : '',
    //     CPE: client.cpe_id ? this.cpeNamebyId(client.cpe_id) : '',
    //     Frequency: client.Type !== 'wired' && client.freq ? client.freq : '',
    //     Channel: client.Type !== 'wired' && client.channel ? client.channel : '',
    //     RSSI: client.Type !== 'wired' && client.rssi ? client.rssi : '',
    //     'Ethernet Interface': client.Type === 'wired' && client.radio_id ? client.radio_id : '',
    //     WLAN: client.Type !== 'wired' && client.wlan_id ? this.wlanNamebyId(client.wlan_id) : ''
    //   }));
    // }
    // formatToCSV(clients) {
    //   if (!clients) {
    //     return [];
    //   }
    //
    //   const csvClients = JSON.parse(JSON.stringify(clients));
    //
    //   // return csvClients.map((client) => ({
    //   //   Type: client.Type ? this.getClientTypeI18nCaption(client.Type) : '',
    //   //   MAC: client.mac ? this.getFormatMac(client.mac) : '',
    //   //   IP: client.ip ? client.ip : '',
    //   //   Authorized: (client.username && client.username !== '' && client.username !== undefined) ? client.username : this.$t('general.no').toLowerCase(),
    //   //   Description: client.data.description ? client.data.description : '',
    //   //   CPE: client.cpe_id ? this.cpeNamebyId(client.cpe_id) : '',
    //   //   Frequency: client.Type !== 'wired' && client.freq ? client.freq : '',
    //   //   Channel: client.Type !== 'wired' && client.channel ? client.channel : '',
    //   //   RSSI: client.Type !== 'wired' && client.rssi ? client.rssi : '',
    //   //   WLAN: client.Type !== 'wired' && client.wlan_id ? this.wlanNamebyId(client.wlan_id) : '',
    //   //   status: `${this.$t(`clients.health${this.getClientHealthStatus(client.health_score)}`)}  ${client.health_score} ${this.$t('clients.scores')}`
    //   // }));
    //   return csvClients.map((client) => {
    //     const result = {};
    //     result[`${this.$t('general.type')}`] = client.Type ? this.getClientTypeI18nCaption(client.Type) : '';
    //     result['MAC'] = client.mac ? this.getFormatMac(client.mac) : '';
    //     result['IP'] =  client.ip ? client.ip : '';
    //     result[`${this.$t('clients.auth')}`] = (client.username && client.username !== '' && client.username !== undefined) ? client.username : this.$t('general.no').toLowerCase();
    //     result[`${this.$t('clients.tableTab_description')}`] = client.data.description ? client.data.description : '';
    //     result[`${this.$t('clients.tableTab_cpe')}`] = client.cpe_id ? this.cpeNamebyId(client.cpe_id) : '';
    //     result[`${this.$t('general.frequency')}`] = client.Type !== 'wired' && client.freq ? client.freq : '';
    //     result[`${this.$t('clients.channel')}`] = client.Type !== 'wired' && client.channel ? client.channel : '';
    //     result['RSSI'] = client.Type !== 'wired' && client.rssi ? client.rssi : '';
    //     result[`${this.$t('clients.tableTab_wlan')}`] = client.Type !== 'wired' && client.wlan_id ? this.wlanNamebyId(client.wlan_id) : '';
    //     result[`${this.$t('clients.tableTab_healthScore')}`] = `${this.$t(`clients.health${this.getClientHealthStatus(client.health_score)}`)} (${client.health_score} ${this.$t('clients.scores')})`;
    //     return result
    //   });
    // },

        formatToCSV(clients) {
      if (!clients) {
        return [];
      }

      const csvClients = JSON.parse(JSON.stringify(clients));

      // return csvClients.map((client) => ({
      //   Type: client.Type ? this.getClientTypeI18nCaption(client.Type) : '',
      //   MAC: client.mac ? this.getFormatMac(client.mac) : '',
      //   IP: client.ip ? client.ip : '',
      //   Authorized: (client.username && client.username !== '' && client.username !== undefined) ? client.username : this.$t('general.no').toLowerCase(),
      //   Description: client.data.description ? client.data.description : '',
      //   CPE: client.cpe_id ? this.cpeNamebyId(client.cpe_id) : '',
      //   Frequency: client.Type !== 'wired' && client.freq ? client.freq : '',
      //   Channel: client.Type !== 'wired' && client.channel ? client.channel : '',
      //   RSSI: client.Type !== 'wired' && client.rssi ? client.rssi : '',
      //   WLAN: client.Type !== 'wired' && client.wlan_id ? this.wlanNamebyId(client.wlan_id) : '',
      //   status: `${this.$t(`clients.health${this.getClientHealthStatus(client.health_score)}`)}  ${client.health_score} ${this.$t('clients.scores')}`
      // }));
      return csvClients.map((client) => {
        const result = {};
        result[`${this.$t('general.type')}`] = client.Type ? this.getClientTypeI18nCaption(client.Type) : '';
        result['MAC'] = client.mac ? this.getFormatMac(client.mac) : '';
        result['IP'] =  client.ip ? client.ip : '';
        // result[`${this.$t('clients.auth')}`] = (client.username && client.username !== '' && client.username !== undefined) ? client.username : this.$t('general.no').toLowerCase();
        // result[`${this.$t('clients.tableTab_description')}`] = client.data.description ? client.data.description : '';
        result[`${this.$t('clients.tableTab_cpe')}`] = client.cpe_id ? this.cpeNamebyId(client.cpe_id) : '';
        result[`${this.$t('general.frequency')}`] = client.Type !== 'wired' && client.freq ? client.freq : '';
        result[`${this.$t('clients.channel')}`] = client.Type !== 'wired' && client.channel ? client.channel : '';
        result['RSSI'] = client.Type !== 'wired' && client.rssi ? client.rssi : '';
        result[`${this.$t('clients.tableTab_wlan')}`] = client.Type !== 'wired' && client.wlan_id ? this.wlanNamebyId(client.wlan_id) : '';
        // result[`${this.$t('clients.tableTab_healthScore')}`] = `${this.$t(`clients.health${this.getClientHealthStatus(client.health_score)}`)} (${client.health_score} ${this.$t('clients.scores')})`;
        return result
      });
    },

    getClientTypeI18nCaption(type) {
      if (type === 'other') {
        return this.$t('clients.editPanelOther')
      }
      if (type === 'camera') {
        return this.$t('clients.editPanelCamera')
      }
      if (type === 'wired') {
        return this.$t('clients.editPanelWired')
      }
      return type
    },
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    }
  },
  // created() {
  //   wlanService.getWlansCompactForFilter(this);
  //   cpeService.getCpesCompactForFilter(this);
  //   this.filters.location = this.userBaseLocation;
  //   // console.log(this.$route.params)
  //   this.$store.commit('setCpesList', false);
  //   this.filters.statusFilter = 'CONNECTED';
  //   this.$store.commit('setClientsList', false);
  //   this.disableEditMode();
  //   // if (this.$route.params.id) {
  //   //   this.filters.statusFilter = '';
  //   //   this.searchValue = this.$route.params.id;
  //   //   this.searchInputHandler(this.searchValue);
  //   // } else {
  //   //   clientService.getPaginatedClients(this, this.limit, this.offset);
  //   // }
  //   if (this.$route.params.id) {
  //     this.filters.statusFilter = '';
  //     this.searchValue = this.$route.params.id;
  //     this.searchInputHandler(this.searchValue);
  //   } else if (this.$route.params.cpeId) {
  //     this.showGoBackToCpeButton = true
  //     // console.log(this.$route.params)
  //     // this.filters.statusFilter = 'CONNECTED';
  //     // this.searchValue = this.$route.params.cpeId;
  //     // this.searchInputHandler(this.searchValue);
  //     this.filters.statusFilter = 'CONNECTED';
  //     // const cpeObj = this.cpeObjbyId(this.$route.params.cpeId);
  //     if (this.$route.params.cpeName) {
  //       this.showGoBackToCpeButtonCPEName = this.$route.params.cpeName
  //       this.filters.cpe_id = this.$route.params.cpeId;
  //       this.selectedCpeInMultiselector = [{ id: this.$route.params.cpeId, name: this.$route.params.cpeName }];
  //       if (this.$route.params.locationId) {
  //         // console.log('init location filter');
  //         this.initializeLocationFilterWhenGoFromCPE(this.$route.params.locationId);
  //       }
  //     } else {
  //       this.searchValue = this.$route.params.cpeId;
  //     }
  //     this.searchByCpeIdHandler(this.searchValue);
  //   } else {
  //     clientService.getPaginatedClients(this, this.limit, this.offset);
  //   }
  // }
  // created() {
  //   wlanService.getWlansCompactForFilter(this);
  //   cpeService.getCpesCompactForFilter(this);
  //   this.filters.location = this.userBaseLocation;
  //   // console.log(this.$route.params)
  //   this.$store.commit('setCpesList', false);
  //   this.filters.statusFilter = 'CONNECTED';
  //   this.$store.commit('setClientsList', false);
  //   this.disableEditMode();
  //   // if (this.$route.params.id) {
  //   //   this.filters.statusFilter = '';
  //   //   this.searchValue = this.$route.params.id;
  //   //   this.searchInputHandler(this.searchValue);
  //   // } else {
  //   //   clientService.getPaginatedClients(this, this.limit, this.offset);
  //   // }
  //   if (this.$route.params.id) {
  //     this.filters.statusFilter = '';
  //     this.searchValue = this.$route.params.id;
  //     this.searchInputHandler(this.searchValue);
  //   } else if (this.$route.params.cpeId) {
  //     this.showGoBackToCpeButton = true
  //     // console.log(this.$route.params)
  //     // this.filters.statusFilter = 'CONNECTED';
  //     // this.searchValue = this.$route.params.cpeId;
  //     // this.searchInputHandler(this.searchValue);
  //     this.filters.statusFilter = 'CONNECTED';
  //     // const cpeObj = this.cpeObjbyId(this.$route.params.cpeId);
  //     if (this.$route.params.cpeName) {
  //       this.showGoBackToCpeButtonCPEName = this.$route.params.cpeName
  //       this.filters.cpe_id = this.$route.params.cpeId;
  //       this.selectedCpeInMultiselector = [{ id: this.$route.params.cpeId, name: this.$route.params.cpeName }];
  //       if (this.$route.params.locationId) {
  //         // console.log('init location filter');
  //         this.initializeLocationFilterWhenGoFromCPE(this.$route.params.locationId);
  //       }
  //     } else {
  //       this.searchValue = this.$route.params.cpeId;
  //     }
  //     this.searchByCpeIdHandler(this.searchValue);
  //   } else if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('clientIdFormClientsStatisic')) {
  //     this.filters.statusFilter = '';
  //     this.searchValue = this.$route.params.clientIdFormClientsStatisic;
  //     // this.searchValue = ''
  //     this.searchInputHandler(this.searchValue);
  //     this.showGoBackToClientsStatisticButton = true;
  //     // а далее логика  в вотчере isLoadingAll
  //
  //   } else if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('locationSelectedFromDashboard')) {
  //     // если переходим cюда из дашборда кликнов по ссылку View Clients в блоке
  //
  //     if (this.$route.params.locationSelectedFromDashboard) {
  //       this.filters.statusFilter = '';
  //       this.filters.location = this.$route.params.locationSelectedFromDashboard.locationId;
  //       this.filters.with_childs = this.$route.params.locationSelectedFromDashboard.with_child
  //       this.showGoBackToDashboardButton = true;
  //
  //     }
  //     this.refreshClientsListQuery();
  //
  //   } else {
  //     clientService.getPaginatedClients(this, this.limit, this.offset);
  //   }
  // }
  //   created() {
  //   let inHeaderLocationSelectedApplying = true;
  //     const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
  //     const goBackFromCpes = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromCpes');
  //     const goBackFromWlans = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromWlans');
  //     const goBackFromEvents = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromEvents');
  //     const needRestoreState = hasLocalStorageDataForRestoring && (goBackFromCpes || goBackFromWlans || goBackFromEvents);
  //     if (needRestoreState) {
  //       this.loadComponentStateDataForRestoreAndRestore();
  //       this.removeComponentStateDataForRestore();
  //
  //     }
  //     if (!needRestoreState && hasLocalStorageDataForRestoring) {
  //       this.removeComponentStateDataForRestore();
  //     }
  //
  //   if (!needRestoreState){
  //   wlanService.getWlansCompactForFilter(this);
  //   cpeService.getCpesCompactForFilter(this);
  //   this.filters.location = this.userBaseLocation;
  //   // console.log(this.$route.params)
  //   this.$store.commit('setCpesList', false);
  //   this.filters.statusFilter = 'CONNECTED';
  //   this.$store.commit('setClientsList', false);
  //   this.disableEditMode();
  //   // if (this.$route.params.id) {
  //   //   this.filters.statusFilter = '';
  //   //   this.searchValue = this.$route.params.id;
  //   //   this.searchInputHandler(this.searchValue);
  //   // } else {
  //   //   clientService.getPaginatedClients(this, this.limit, this.offset);
  //   // }
  //   if (this.$route.params.id) {
  //     inHeaderLocationSelectedApplying = false;
  //     this.filters.statusFilter = '';
  //     this.searchValue = this.$route.params.id;
  //     this.searchInputHandler(this.searchValue);
  //   } else if (this.$route.params.cpeId) {
  //     inHeaderLocationSelectedApplying = false;
  //     this.showGoBackToCpeButton = true
  //     // console.log(this.$route.params)
  //     // this.filters.statusFilter = 'CONNECTED';
  //     // this.searchValue = this.$route.params.cpeId;
  //     // this.searchInputHandler(this.searchValue);
  //     this.filters.statusFilter = 'CONNECTED';
  //     // const cpeObj = this.cpeObjbyId(this.$route.params.cpeId);
  //     if (this.$route.params.cpeName) {
  //       this.showGoBackToCpeButtonCPEName = this.$route.params.cpeName
  //       this.filters.cpe_id = this.$route.params.cpeId;
  //       this.selectedCpeInMultiselector = [{ id: this.$route.params.cpeId, name: this.$route.params.cpeName }];
  //       if (this.$route.params.locationId) {
  //         // console.log('init location filter');
  //         this.initializeLocationFilterWhenGoFromCPE(this.$route.params.locationId);
  //       }
  //     } else {
  //       inHeaderLocationSelectedApplying = false;
  //       this.searchValue = this.$route.params.cpeId;
  //     }
  //     this.searchByCpeIdHandler(this.searchValue);
  //   } else if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('clientIdFormClientsStatisic')) {
  //     inHeaderLocationSelectedApplying = false;
  //     this.filters.statusFilter = '';
  //     this.searchValue = this.$route.params.clientIdFormClientsStatisic;
  //     // this.searchValue = ''
  //     this.searchInputHandler(this.searchValue);
  //     this.showGoBackToClientsStatisticButton = true;
  //     // а далее логика  в вотчере isLoadingAll
  //
  //   } else if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('locationSelectedFromDashboard')) {
  //     inHeaderLocationSelectedApplying = false;
  //     // если переходим cюда из дашборда кликнов по ссылку View Clients в блоке
  //
  //     if (this.$route.params.locationSelectedFromDashboard) {
  //       this.filters.statusFilter = '';
  //       this.filters.location = this.$route.params.locationSelectedFromDashboard.locationId;
  //       this.filters.with_childs = this.$route.params.locationSelectedFromDashboard.with_child
  //       this.showGoBackToDashboardButton = true;
  //
  //     }
  //     this.refreshClientsListQuery();
  //
  //   } else {
  //
  //     if (this.$store.state.inHeaderLocationSelectModeEnabled &&
  //       inHeaderLocationSelectedApplying) {
  //       let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
  //       let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
  //       if (!locationForFiltering || locationForFiltering === '') {
  //         // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
  //         locationForFiltering = 'All locations';
  //       }
  //
  //       if (locationForFiltering === 'All locations') {
  //       this.locationInFiltersData = [];
  //     } else {
  //       this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
  //     }
  //
  //       this.filters.location = locationForFiltering;
  //       this.filters.with_childs = withChildsForFiltering;
  //     }
  //
  //     clientService.getPaginatedClientsV2(this, this.limit, this.offset);
  //   }
  //   }
  // }
  created() {
    this.WSADDR_URL = WSADDR_URL;
    let inHeaderLocationSelectedApplying = true;
      const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
      const goBackFromCpes = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromCpes');
      const goBackFromWlans = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromWlans');
      const goBackFromEvents = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromEvents');
      const needRestoreState = hasLocalStorageDataForRestoring && (goBackFromCpes || goBackFromWlans || goBackFromEvents);
      if (needRestoreState) {
        this.loadComponentStateDataForRestoreAndRestore();
        this.removeComponentStateDataForRestore();

      }
      if (!needRestoreState && hasLocalStorageDataForRestoring) {
        this.removeComponentStateDataForRestore();
      }

    if (!needRestoreState){
    wlanService.getWlansCompactForFilter(this);
    cpeService.getCpesCompactForFilter(this);
    this.filters.location = this.userBaseLocation;
    // console.log(this.$route.params)
    this.$store.commit('setCpesList', false);
    this.filters.statusFilter = 'CONNECTED';
    this.$store.commit('setClientsList', false);
    this.disableEditMode();
    // if (this.$route.params.id) {
    //   this.filters.statusFilter = '';
    //   this.searchValue = this.$route.params.id;
    //   this.searchInputHandler(this.searchValue);
    // } else {
    //   clientService.getPaginatedClients(this, this.limit, this.offset);
    // }
    if (this.$route.params.id) {
      inHeaderLocationSelectedApplying = false;
      this.filters.statusFilter = '';
      this.searchValue = this.$route.params.id;
      if (this.isSelectedDefaultLocationForRequests) {
        this.applySelectedInHeaderLocation();
      }
      this.searchInputHandler(this.searchValue);
    } else if (this.$route.params.cpeId) {
      inHeaderLocationSelectedApplying = false;
      this.showGoBackToCpeButton = true
      // console.log(this.$route.params)
      // this.filters.statusFilter = 'CONNECTED';
      // this.searchValue = this.$route.params.cpeId;
      // this.searchInputHandler(this.searchValue);
      this.filters.statusFilter = 'CONNECTED';
      // const cpeObj = this.cpeObjbyId(this.$route.params.cpeId);
      if (this.$route.params.cpeName) {
        this.showGoBackToCpeButtonCPEName = this.$route.params.cpeName
        this.filters.cpe_id = this.$route.params.cpeId;
        this.selectedCpeInMultiselector = [{ id: this.$route.params.cpeId, name: this.$route.params.cpeName }];
        if (this.isSelectedDefaultLocationForRequests) {
        this.applySelectedInHeaderLocation();
      }
        if (this.$route.params.locationId) {
          // console.log('init location filter');
          this.initializeLocationFilterWhenGoFromCPE(this.$route.params.locationId);
        }
      } else {
        inHeaderLocationSelectedApplying = false;
              if (this.isSelectedDefaultLocationForRequests) {
        this.applySelectedInHeaderLocation();
      }
        this.searchValue = this.$route.params.cpeId;
      }
      this.searchByCpeIdHandler(this.searchValue);
    } else if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('clientIdFormClientsStatisic')) {
      inHeaderLocationSelectedApplying = false;
      if (this.isSelectedDefaultLocationForRequests) {
        this.applySelectedInHeaderLocation();
      }
      this.filters.statusFilter = '';
      this.searchValue = this.$route.params.clientIdFormClientsStatisic;
      // this.searchValue = ''
      this.searchInputHandler(this.searchValue);
      this.showGoBackToClientsStatisticButton = true;
      // а далее логика  в вотчере isLoadingAll

    } else if (this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('locationSelectedFromDashboard')) {
      inHeaderLocationSelectedApplying = false;
      // если переходим cюда из дашборда кликнов по ссылку View Clients в блоке

      if (this.$route.params.locationSelectedFromDashboard) {
        this.filters.statusFilter = '';
        this.filters.location = this.$route.params.locationSelectedFromDashboard.locationId;
        this.filters.with_childs = this.$route.params.locationSelectedFromDashboard.with_child
        this.showGoBackToDashboardButton = true;

      }
      if (this.isSelectedDefaultLocationForRequests) {
        this.applySelectedInHeaderLocation();
      }
      this.refreshClientsListQuery();

    } else {

      if (this.$store.state.inHeaderLocationSelectModeEnabled &&
        inHeaderLocationSelectedApplying) {
        let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
        let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
        if (!locationForFiltering || locationForFiltering === '') {
          // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
          locationForFiltering = 'All locations';
        }

        if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

        this.filters.location = locationForFiltering;
        this.filters.with_childs = withChildsForFiltering;
      }
    }
    }
  },
  mounted() {
    // начальная загрузка клиентов
    function initFilterQueryHandler(newSearchBarQuery) {
      if (newSearchBarQuery) {
        this.filters.statusFilter = '';
        this.searchValue = newSearchBarQuery;
      } else {
        clientService.getPaginatedClientsV2(this, this.limit, this.offset);
      }
    }

    // обновление клиентов при изменении searchQuery параметра
    function updateFilterQueryHandler(newSearchBarQuery) {
      this.filters.statusFilter = '';
      this.searchValue = newSearchBarQuery;
    }

    // подписки на изменение searchQuery
    this.subscribeOnSearchBarInit(initFilterQueryHandler);
    this.subscribeOnSearchBarUpdate(updateFilterQueryHandler);

    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      clientService.refreshClientsV2(this, 'spinner');
    });
  }
};
</script>

<style lang="scss" scoped>
.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}

.emptystate-table-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.muted {
  color: var(--gray-light);
}

.client {
  display: flex;
}

.client-list,
.client-edit {
  transition: width 0.2s;
  will-change: transform;
}

.client-list {
  display: inline-block;
  width: 100%;
}

.client-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
}

.client-edit .card-header {
  border: 0px;
}

.edit-visible .client-list {
  transition: width 0.2s;
  margin-right: 10px;
  min-width: 580px;
  width: 60%;
  will-change: transform;
}

.edit-visible .client-edit {
  transition: width 0.2s;
  min-width: 300px;
  width: 40%;
  will-change: transform;
}

.edit-btn-close {
  line-height: 14px;
  font-size: 14px !important;
  height: 27.5px;
  width: 29px;
}

.client-row {
  transition: background 0.15s;
  cursor: pointer;
}

.client-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.client-row.selected {
  background: #d9e3ec;
}

.client-state {
  display: flex;
  flex-direction: row;

  &--column {
    flex-direction: column;
  }

  &__main {
  }
}

.clients-camera-button {
  cursor: pointer;
  padding-left: 0.5rem;
}
.clients-camera-button:hover i {
  transition: transform 0.2s;
}

.clients-camera-button:hover i {
  transform: scale(1.4);
}

.badge-client {
  position: relative;
  white-space: nowrap;
}

.client-name {
  white-space: nowrap;
  position: relative;
  & i {
    position: absolute;
    display: block;
    width: 15px;
    top: 2px;
    left: -38px;
  }

  & i.fa-mobile {
    /*left: 3px;*/
  }

  & .badge-client {
    margin-left: 40px;
  }
}
</style>

<style lang="scss" scoped>
.fade-in-clients-enter-active,
.fade-in-clients-leave-active {
  transition: opacity 0.3s;
}

.fade-in-clients-enter,
.fade-in-clients-leave-to {
  opacity: 0;
}
.health-status-buttons-block {
  /*margin-left: 1em;*/
}
.filter-in-clients {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1rem;
}
.health-status-buttons-info {
}
.health-status-badge-inactive {
  background-color: #4f5a66 !important;
  color: white !important;
}
.health-status-badge-poor {
  background-color: #f86c6b !important;
  color: white !important;
}
.health-status-badge-fair {
  background-color: #f8cb00 !important;
  color: white !important;
}
.health-status-badge-good {
  background-color: #4dbd74 !important;
  color: white !important;
}

@media (max-width: 1130px) {
  .client-list {
    min-width: 870px;
  }

  .edit-visible .client-list {
    min-width: 580px;
  }
}

.description-row {
  word-break: break-word;
  overflow: hidden;
}

.client-info {
  display: flex;
}

.client-info-general,
.client-info-authorization {
  flex: 1;
}

.table-column-switcher {
  position: absolute;
  right: -15px;
}

.loader-backdrop-clients:after {
  position: absolute;
  top: 30px;
}
.no-scroll-bar {
  overflow-y: auto;

  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll-bar ::-webkit-scrollbar {
  /* chrome based */
  width: 0px; /* ширина scrollbar'a */
}
.btn-events-in-clients {
  // max-height: 50px !important;
  font-size: 0.8rem !important;
}
</style>
<style>
              .input-group-for-location-select-in-clients--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-clients--filters .select__list-container {
    width: 400px;
  }
/*.height-custom-for-filters-in-clients {*/
/*  height: 42px !important;*/
/*}*/

.min-width-for-filters-in-clients {
  /*max-width: 250px;*/
}

.height-custom-for-filters-in-clients-custom-padding {
  /*padding-top: 11px !important;*/
}

.location-filters-in-clients {
  min-width: 50px !important;
  width: 100% !important;
}

.multiselect-in-clients .multiselect__content-wrapper {
  overflow-x: hidden !important;
  min-width: 500px;
  width: fit-content !important;
  text-align: left;
  /*    overflow-x: hidden;*/
  /*text-overflow: ellipsis;*/
}
.multiselect-in-clients .multiselect__tag {
  /*padding-bottom:0px !important;*/
  /*padding-top:1px !important;*/
  margin-bottom: 2px !important;
}
</style>
<style scoped>
.lables-in-clients-filters {
  max-width: 100%;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.with-child {
  position: relative;
  font-size: 0.7em;
  padding: 0;
  margin: 0em;
  border: 0;
  top: -1px;
}

.with-child-wrapper {
  /*margin-left: 0.3em;*/
}

.switcher-with-childs-in-clients label.switch {
  margin: 0 !important;
}

.switcher-with-childs-in-clients label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-with-childs-in-clients span.switch-handle,
.switcher-with-childs-in-clients span.switch-label {
  height: 27px !important;
}

.switcher-with-childs-in-clients-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  margin-left: 5px !important;
  height: 27px !important;
}

.switcher-with-childs-in-clients-caption {
  /*margin: 0px !important;*/
  margin-left: -12px !important;
  text-align: center;
  max-width: 50px;
}

/*.enable-filters-button {*/
/*  margin: 0px !important;*/
/*  margin-top: 10px !important;*/
/*  padding: 0 !important;*/
/*  border: 0 !important;*/
/*}*/

/*.flex-gap-1 {*/
/*  gap: 1em !important;*/
/*}*/

/*.last-row-filters-block-in-statclients {*/
/*  width: 80% !important;*/
/*}*/

/*.button-apply-last-row-filters-block-in-statclients {*/
/*  width: 20% !important;*/
/*}*/

.switcher-with-childs-in-clients label.switch {
  margin-bottom: 0 !important;
}

.switcher-with-childs-in-clients {
  top: 5px !important;
  position: relative !important;
}
.location-filter-with-childs {
  width: 150px !important;
}
</style>
