/**
 * Для дисконнеккта и деавторизации клиентов.
 */

import { AXIOS_INSTANCE } from '@/api';
import store from '@/store';
import { API_URL } from '@/config';

/**
 * Асинхронная функция для дисконнеккта и деавторизации клиентов.
 * @param {Object} clientObject - объект  клиента
 *
 * @returns {Promise<Object>} - Обещание с данными.
 * @throws {Error} - Ошибка при дисконнекте и деавторизации клиентов.
 */
const clientDisconnectAndDeauthorizeDataFetcher = async (clientObject) => {
  const clientObjectForQuery = {
    cpe_id: clientObject.cpe_id,
    mac: clientObject.mac,
    mac_addr: clientObject.mac_addr
  };
  const query = { action: 'U', items: { [clientObject.mac]: clientObjectForQuery }, q: { disconnect: '1' } };

  try {
    const response = await AXIOS_INSTANCE.post(`${API_URL}/api/clients`, query);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const clientDisconnectAndDeauthorizeService = {
  /**
   * Асинхронно дисконнектит и деавторизует клиента.
   *
   * @param {Object} clientObject - объект  клиента
   * @param {Object} [options] - Дополнительные опции запроса.
   * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
   * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
   * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
   * @param {String} [options.successMessage] - Сообщение об успешном выполнении запроса.
   * @returns {Promise<Object>} - Обещание с ответом на запрос.
   * @throws {Error} - Ошибка при дисконнекте и деавторизации клиентов.
   */
  clientDisconnectAndDeauthorize: async (
    clientObject,
    options = {
      onLoading: undefined,
      onSuccess: undefined,
      onError: undefined,
      successMessage: undefined
    }
    // eslint-disable-next-line consistent-return
  ) => {
    const { onLoading, onSuccess, onError, successMessage } = options;

    try {
      if (onLoading) {
        onLoading();
      }

      store.commit('setLoading');
      const response = await clientDisconnectAndDeauthorizeDataFetcher(clientObject);
      const { data, status, description } = response;

      if (status === 'error') {
        throw new Error(description);
      }

      if (status === 'success' && onSuccess) {
        onSuccess(data);
      }

      if (status === 'success') {
        store.commit('setSuccess', successMessage);

        return Promise.resolve(data);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
      store.commit('setError', error);
    }
  }
};

export default clientDisconnectAndDeauthorizeService;
